export const BUSINESSLIST = [
  {
    Label: 'Accounting Firm',
  },
  {
    Label: 'Advertising Agency',
  },
  {
    Label: 'Architectural Firm',
  },
  {
    Label: 'Art Gallery',
  },
  {
    Label: 'Auto Repair Shop',
  },
  {
    Label: 'Bakery',
  },
  {
    Label: 'Barber Supply Store',
  },
  {
    Label: 'Barbershop',
  },
  {
    Label: 'Beauty Salon',
  },
  {
    Label: 'Beauty Supply Store',
  },
  {
    Label: 'Bed and Breakfast',
  },
  {
    Label: 'Bicycle Shop',
  },
  {
    Label: 'Business Coaching Service',
  },
  {
    Label: 'Candle Shop',
  },
  {
    Label: 'Car Dealership',
  },
  {
    Label: 'Car Rental Agency',
  },
  {
    Label: 'Car Wash',
  },
  {
    Label: 'Catering Service',
  },
  {
    Label: 'Clothing Boutique',
  },
  {
    Label: 'Coffee Shop',
  },
  {
    Label: 'Computer Repair Service',
  },
  {
    Label: 'Consulting Firm',
  },
  {
    Label: 'Dance Studio',
  },
  {
    Label: 'Daycare Center',
  },
  {
    Label: 'Deli',
  },
  {
    Label: 'Dental Clinic',
  },
  {
    Label: 'Design Studio',
  },
  {
    Label: 'Donut Shop',
  },
  {
    Label: 'Dry Cleaning Service',
  },
  {
    Label: 'Electrical Contractor',
  },
  {
    Label: 'Engineering Firm',
  },
  {
    Label: 'Event Planning Company',
  },
  {
    Label: 'Event Venue',
  },
  {
    Label: 'Farm',
  },
  {
    Label: 'Farmers Market',
  },
  {
    Label: 'Financial Advisory Firm',
  },
  {
    Label: 'Financial Planning Firm',
  },
  {
    Label: 'Fitness Center',
  },
  {
    Label: 'Flower Shop',
  },
  {
    Label: 'Food Truck',
  },
  {
    Label: 'Furniture Store',
  },
  {
    Label: 'Gift Shop',
  },
  {
    Label: 'Grocery Store',
  },
  {
    Label: 'Gym',
  },
  {
    Label: 'Hair Salon',
  },
  {
    Label: 'Hardware Store',
  },
  {
    Label: 'Healthcare Clinic',
  },
  {
    Label: 'Home Improvement Contractor',
  },
  {
    Label: 'Hotel',
  },
  {
    Label: 'Ice Cream Shop',
  },
  {
    Label: 'Insurance Agency',
  },
  {
    Label: 'Interior Design Firm',
  },
  {
    Label: 'Jewellery Store',
  },
  {
    Label: 'Juice Bar',
  },
  {
    Label: 'Law Firm',
  },
  {
    Label: 'Lawn Care Service',
  },
  {
    Label: 'Locksmith Service',
  },
  {
    Label: 'Marketing Agency',
  },
  {
    Label: 'Massage Therapy Clinic',
  },
  {
    Label: 'Mobile App Development Company',
  },
  {
    Label: 'Moving Company',
  },
  {
    Label: 'Music Studio',
  },
  {
    Label: 'Nail Salon',
  },
  {
    Label: 'Online Retailer',
  },
  {
    Label: 'Optometry Clinic',
  },
  {
    Label: 'Painting Contractor',
  },
  {
    Label: 'Pawn Shop',
  },
  {
    Label: 'Personal Training Studio',
  },
  {
    Label: 'Pest Control Service',
  },
  {
    Label: 'Pet Grooming Salon',
  },
  {
    Label: 'Pharmacy',
  },
  {
    Label: 'Photography Studio',
  },
  {
    Label: 'Physical Therapy Clinic',
  },
  {
    Label: 'Pizza Restaurant',
  },
  {
    Label: 'Plumbing Service',
  },
  {
    Label: 'Real Estate Agency',
  },
  {
    Label: 'Restaurant',
  },
  {
    Label: 'Roofing Contractor',
  },
  {
    Label: 'Security Guard Company',
  },
  {
    Label: 'Software Development Company',
  },
  {
    Label: 'Spa',
  },
  {
    Label: 'Sports Equipment Store',
  },
  {
    Label: 'Tattoo Studio',
  },
  {
    Label: 'Taxi Service',
  },
  {
    Label: 'Tea House',
  },
  {
    Label: 'Travel Agency',
  },
  {
    Label: 'Veterinary Clinic',
  },
  {
    Label: 'Web Design Agency',
  },
  {
    Label: 'Wedding Planning Service',
  },
  {
    Label: 'Yoga Studio',
  },
];
