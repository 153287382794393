export const ukcities = [
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Aberdeen',
    'coordinates.lat': 57.149651,
    'coordinates.lon': -2.099075,
    Postcode: 'AB10',
    postcode: 'AB10',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Abingdon',
    'coordinates.lat': 51.671091,
    'coordinates.lon': -1.28278,
    Postcode: 'OX14',
    postcode: 'OX14',
    Region: 'Abingdon',
    'UK region': 'South East',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Bangor',
    'coordinates.lat': 53.227943,
    'coordinates.lon': -4.1292,
    Postcode: 'LL57',
    postcode: 'LL57',
    Region: 'Bangor',
    'UK region': 'Wales',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Bath',
    'coordinates.lat': 51.375801,
    'coordinates.lon': -2.3599039,
    Postcode: 'BA1',
    postcode: 'BA1',
    Region: 'Bath',
    'UK region': 'South West',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Belfast',
    'coordinates.lat': 54.597285,
    'coordinates.lon': -5.93012,
    Postcode: 'BT1',
    postcode: 'BT1',
    Region: 'Belfast',
    'UK region': 'Northern Ireland',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Birmingham',
    'coordinates.lat': 52.486243,
    'coordinates.lon': -1.890401,
    Postcode: 'B1',
    postcode: 'B1',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Bradford',
    'coordinates.lat': 53.795984,
    'coordinates.lon': -1.759398,
    Postcode: 'BD1',
    postcode: 'BD1',
    Region: 'Bradford',
    'UK region': 'Yorkshire and the Humber',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Brackley',
    'coordinates.lat': 52.032631,
    'coordinates.lon': -1.141151,
    Postcode: 'NN13',
    postcode: 'NN13',
    Region: 'Brackley',
    'UK region': 'East Midlands',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Brighton',
    'coordinates.lat': 50.82253,
    'coordinates.lon': -0.137163,
    Postcode: 'BN1',
    postcode: 'BN1',
    Region: 'Brighton',
    'UK region': 'South East',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Bristol',
    'coordinates.lat': 51.454514,
    'coordinates.lon': -2.58791,
    Postcode: 'BS1',
    postcode: 'BS1',
    Region: 'Bristol',
    'UK region': 'South West',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Cambridge',
    'coordinates.lat': 52.205337,
    'coordinates.lon': 0.121817,
    Postcode: 'CB1',
    postcode: 'CB1',
    Region: 'Cambridge',
    'UK region': 'East of England',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Cardiff',
    'coordinates.lat': 51.481583,
    'coordinates.lon': -3.17909,
    Postcode: 'CF10',
    postcode: 'CF10',
    Region: 'Cardiff',
    'UK region': 'Wales',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Chichester',
    'coordinates.lat': 50.836654,
    'coordinates.lon': -0.78006,
    Postcode: 'PO19',
    postcode: 'PO19',
    Region: 'Chichester',
    'UK region': 'South East',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Coventry',
    'coordinates.lat': 52.406822,
    'coordinates.lon': -1.519693,
    Postcode: 'CV1',
    postcode: 'CV1',
    Region: 'Coventry',
    'UK region': 'West Midlands',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Derby',
    'coordinates.lat': 52.92253,
    'coordinates.lon': -1.474619,
    Postcode: 'DE1',
    postcode: 'DE1',
    Region: 'Derby',
    'UK region': 'East Midlands',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Durham',
    'coordinates.lat': 54.77525,
    'coordinates.lon': -1.584852,
    Postcode: 'DH1',
    postcode: 'DH1',
    Region: 'Durham',
    'UK region': 'North East',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Edinburgh',
    'coordinates.lat': 55.953252,
    'coordinates.lon': -3.188267,
    Postcode: 'EH1',
    postcode: 'EH1',
    Region: 'Edinburgh',
    'UK region': 'Scotland',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Exeter',
    'coordinates.lat': 50.718412,
    'coordinates.lon': -3.533899,
    Postcode: 'EX1',
    postcode: 'EX1',
    Region: 'Exeter',
    'UK region': 'South West',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Exmouth',
    'coordinates.lat': 50.620587,
    'coordinates.lon': -3.41345,
    Postcode: 'EX8',
    postcode: 'EX8',
    Region: 'Exmouth',
    'UK region': 'South West',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Glasgow',
    'coordinates.lat': 55.864237,
    'coordinates.lon': -4.251806,
    Postcode: 'G1',
    postcode: 'G1',
    Region: 'Glasgow',
    'UK region': 'Scotland',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Gloucester',
    'coordinates.lat': 51.864244,
    'coordinates.lon': -2.238156,
    Postcode: 'GL1',
    postcode: 'GL1',
    Region: 'Gloucester',
    'UK region': 'South West',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Hull',
    'coordinates.lat': 53.767623,
    'coordinates.lon': -0.327419,
    Postcode: 'HU1',
    postcode: 'HU1',
    Region: 'Hull',
    'UK region': 'Yorkshire and the Humber',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Leeds',
    'coordinates.lat': 53.800755,
    'coordinates.lon': -1.549077,
    Postcode: 'LS1',
    postcode: 'LS1',
    Region: 'Leeds',
    'UK region': 'Yorkshire and the Humber',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Lichfield',
    'coordinates.lat': 52.6925953,
    'coordinates.lon': -1.8189572,
    Postcode: 'WS13',
    postcode: 'WS13',
    Region: 'Lichfield',
    'UK region': 'West Midlands',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Liverpool',
    'coordinates.lat': 53.408371,
    'coordinates.lon': -2.991573,
    Postcode: 'L1',
    postcode: 'L1',
    Region: 'Liverpool',
    'UK region': 'North West',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'London',
    'coordinates.lat': 51.507351,
    'coordinates.lon': -0.127758,
    Postcode: 'EC1A',
    postcode: 'EC1A',
    Region: 'London',
    'UK region': 'London',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Maidenhead',
    'coordinates.lat': 51.522743,
    'coordinates.lon': -0.71744,
    Postcode: 'SL6',
    postcode: 'SL6',
    Region: 'Maidenhead',
    'UK region': 'South East',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Manchester',
    'coordinates.lat': 53.480759,
    'coordinates.lon': -2.242631,
    Postcode: 'M1',
    postcode: 'M1',
    Region: 'Manchester',
    'UK region': 'North West',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Newcastle',
    'coordinates.lat': 54.978252,
    'coordinates.lon': -1.617439,
    Postcode: 'NE1',
    postcode: 'NE1',
    Region: 'Newcastle',
    'UK region': 'North East',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Norwich',
    'coordinates.lat': 52.630886,
    'coordinates.lon': 1.297355,
    Postcode: 'NR1',
    postcode: 'NR1',
    Region: 'Norwich',
    'UK region': 'East of England',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Nottingham',
    'coordinates.lat': 52.954783,
    'coordinates.lon': -1.158109,
    Postcode: 'NG1',
    postcode: 'NG1',
    Region: 'Nottingham',
    'UK region': 'East Midlands',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Oxford',
    'coordinates.lat': 51.752022,
    'coordinates.lon': -1.257677,
    Postcode: 'OX1',
    postcode: 'OX1',
    Region: 'Oxford',
    'UK region': 'South East',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Perth',
    'coordinates.lat': 56.395183,
    'coordinates.lon': -3.431481,
    Postcode: 'PH1',
    postcode: 'PH1',
    Region: 'Perth',
    'UK region': 'Scotland',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Peterborough',
    'coordinates.lat': 52.569498,
    'coordinates.lon': -0.24053,
    Postcode: 'PE1',
    postcode: 'PE1',
    Region: 'Peterborough',
    'UK region': 'East of England',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Plymouth',
    'coordinates.lat': 50.375456,
    'coordinates.lon': -4.142656,
    Postcode: 'PL1',
    postcode: 'PL1',
    Region: 'Plymouth',
    'UK region': 'South West',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Portsmouth',
    'coordinates.lat': 50.816667,
    'coordinates.lon': -1.083333,
    Postcode: 'PO1',
    postcode: 'PO1',
    Region: 'Portsmouth',
    'UK region': 'South East',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Reading',
    'coordinates.lat': 51.454264,
    'coordinates.lon': -0.97813,
    Postcode: 'RG1',
    postcode: 'RG1',
    Region: 'Reading',
    'UK region': 'South East',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Ripon',
    'coordinates.lat': 54.135498,
    'coordinates.lon': -1.520707,
    Postcode: 'HG4',
    postcode: 'HG4',
    Region: 'Ripon',
    'UK region': 'Yorkshire and the Humber',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Salford',
    'coordinates.lat': 53.487523,
    'coordinates.lon': -2.290126,
    Postcode: 'M5',
    postcode: 'M5',
    Region: 'Salford',
    'UK region': 'North West',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Sheffield',
    'coordinates.lat': 53.381129,
    'coordinates.lon': -1.470085,
    Postcode: 'S1',
    postcode: 'S1',
    Region: 'Sheffield',
    'UK region': 'Yorkshire and the Humber',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Skegness',
    'coordinates.lat': 53.1439,
    'coordinates.lon': 0.33684,
    Postcode: 'PE25',
    postcode: 'PE25',
    Region: 'Skegness',
    'UK region': 'East Midlands',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Southampton',
    'coordinates.lat': 50.9097,
    'coordinates.lon': -1.404351,
    Postcode: 'SO14',
    postcode: 'SO14',
    Region: 'Southampton',
    'UK region': 'South East',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'St Albans',
    'coordinates.lat': 51.751944,
    'coordinates.lon': -0.336268,
    Postcode: 'AL1',
    postcode: 'AL1',
    Region: 'St Albans',
    'UK region': 'East of England',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Stirling',
    'coordinates.lat': 56.116522,
    'coordinates.lon': -3.936902,
    Postcode: 'FK8',
    postcode: 'FK8',
    Region: 'Stirling',
    'UK region': 'Scotland',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Sunderland',
    'coordinates.lat': 54.904621,
    'coordinates.lon': -1.382217,
    Postcode: 'SR1',
    postcode: 'SR1',
    Region: 'Sunderland',
    'UK region': 'North East',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Truro',
    'coordinates.lat': 50.263195,
    'coordinates.lon': -5.051063,
    Postcode: 'TR1',
    postcode: 'TR1',
    Region: 'Truro',
    'UK region': 'South West',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Wells',
    'coordinates.lat': 51.209034,
    'coordinates.lon': -2.647978,
    Postcode: 'BA5',
    postcode: 'BA5',
    Region: 'Wells',
    'UK region': 'South West',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Winchester',
    'coordinates.lat': 51.062663,
    'coordinates.lon': -1.316936,
    Postcode: 'SO23',
    postcode: 'SO23',
    Region: 'Winchester',
    'UK region': 'South East',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Wolverhampton',
    'coordinates.lat': 52.586973,
    'coordinates.lon': -2.12882,
    Postcode: 'WV1',
    postcode: 'WV1',
    Region: 'Wolverhampton',
    'UK region': 'West Midlands',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'Worcester',
    'coordinates.lat': 52.192001,
    'coordinates.lon': -2.2203,
    Postcode: 'WR1',
    postcode: 'WR1',
    Region: 'Worcester',
    'UK region': 'West Midlands',
  },
  {
    _id: '62debd14772a5f275c48f5b8',
    Area: 'York',
    'coordinates.lat': 53.959,
    'coordinates.lon': -1.08154,
    Postcode: 'YO1',
    postcode: 'YO1',
    Region: 'York',
    'UK region': 'Yorkshire and the Humber',
  },
];
