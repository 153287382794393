export const INITIALUK = [
  {
    coordinates: {lon: -2.1202461, lat: 57.1353138},
    Postcode: 'AB10',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
    Area: 'Aberdeen city centre, Bridge of Dee, Mannofield',
    postcode: 'AB10',
  },
  {
    coordinates: {lon: -2.0928839, lat: 57.1392011},
    Postcode: 'AB11',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
    Area: 'Aberdeen city centre, Torry',
    postcode: 'AB11',
  },
  {
    coordinates: {lon: -2.1129498, lat: 57.1020973},
    Postcode: 'AB12',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
    Area: 'Aberdeen, Altens, Ardoe, Banchory Devenick, Blairs, Bridge of Dee, Cove Bay, Maryculter, Nigg, Portlethen',
    postcode: 'AB12',
  },
  {
    coordinates: {lon: -2.2368915, lat: 57.1082022},
    Postcode: 'AB13',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
    Area: 'Milltimber',
    postcode: 'AB13',
  },
  {
    coordinates: {lon: -2.2713239, lat: 57.1004493},
    Postcode: 'AB14',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
    Area: 'Peterculter, Upper Anguston',
    postcode: 'AB14',
  },
  {
    coordinates: {lon: -2.16861, lat: 57.1375445},
    Postcode: 'AB15',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
    Area: 'Aberdeen, Bieldside, Craigiebuckler, Cults, Hazlehead, Kingswells, Mannofield, Milltimber',
    postcode: 'AB15',
  },
  {
    coordinates: {lon: -2.1566958, lat: 57.1600441},
    Postcode: 'AB16',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
    Area: 'Aberdeen, Mastrick, Northfield, Middlefield, Cornhill',
    postcode: 'AB16',
  },
  {
    coordinates: {lon: -2.2016004, lat: 57.2093798},
    Postcode: 'AB21',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
    Area: 'Aberdeen, Blackburn, Bucksburn, Dyce, Fintray, Kinellar, Newmachar, Whiterashes',
    postcode: 'AB21',
  },
  {
    coordinates: {lon: -2.1223591, lat: 57.1865864},
    Postcode: 'AB22',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
    Area: 'Aberdeen, Bridge of Don, Danestone, Grandholm, Persley',
    postcode: 'AB22',
  },
  {
    coordinates: {lon: -2.0892647, lat: 57.2132774},
    Postcode: 'AB23',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
    Area: 'Aberdeen, Balmedie, Belhelvie, Bridge of Don, Potterton, Whitecairns',
    postcode: 'AB23',
  },
  {
    coordinates: {lon: -2.1079218, lat: 57.1639292},
    Postcode: 'AB24',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
    Area: 'Aberdeen, Old Aberdeen, Woodside, Tillydrone, Seaton, Bedford',
    postcode: 'AB24',
  },
  {
    coordinates: {lon: -2.1140756, lat: 57.1527168},
    Postcode: 'AB25',
    Region: 'Aberdeen',
    'UK region': 'Scotland',
    Area: 'Aberdeen city centre, Kittybrewster, Foresterhill, Rosemount, George Street',
    postcode: 'AB25',
  },
  {
    coordinates: {lon: -2.4778654, lat: 56.8467032},
    Postcode: 'AB30',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Laurencekirk',
    postcode: 'AB30',
  },
  {
    coordinates: {lon: -2.522251, lat: 57.0729813},
    Postcode: 'AB31',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Banchory',
    postcode: 'AB31',
  },
  {
    coordinates: {lon: -2.317922, lat: 57.1550293},
    Postcode: 'AB32',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Westhill',
    postcode: 'AB32',
  },
  {
    coordinates: {lon: -2.740868, lat: 57.2255966},
    Postcode: 'AB33',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Alford',
    postcode: 'AB33',
  },
  {
    coordinates: {lon: -2.8113866, lat: 57.0936452},
    Postcode: 'AB34',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Aboyne',
    postcode: 'AB34',
  },
  {
    coordinates: {lon: -3.1496008, lat: 57.0384103},
    Postcode: 'AB35',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Ballater',
    postcode: 'AB35',
  },
  {
    coordinates: {lon: -3.0683197, lat: 57.1971865},
    Postcode: 'AB36',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Strathdon',
    postcode: 'AB36',
  },
  {
    coordinates: {lon: -3.3524834, lat: 57.3275842},
    Postcode: 'AB37',
    Region: 'Moray',
    'UK region': 'Scotland',
    Area: 'Ballindalloch',
    postcode: 'AB37',
  },
  {
    coordinates: {lon: -3.2264677, lat: 57.4863473},
    Postcode: 'AB38',
    Region: 'Moray',
    'UK region': 'Scotland',
    Area: 'Aberlour',
    postcode: 'AB38',
  },
  {
    coordinates: {lon: -2.2170154, lat: 56.979377},
    Postcode: 'AB39',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Stonehaven, Newtonhill',
    postcode: 'AB39',
  },
  {
    coordinates: {lon: -2.1040725, lat: 57.3739596},
    Postcode: 'AB41',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Ellon',
    postcode: 'AB41',
  },
  {
    coordinates: {lon: -1.8922218, lat: 57.5010193},
    Postcode: 'AB42',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Hatton, St. Fergus',
    postcode: 'AB42',
  },
  {
    coordinates: {lon: -2.0442865, lat: 57.6572238},
    Postcode: 'AB43',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Crimond',
    postcode: 'AB43',
  },
  {
    coordinates: {lon: -2.4922135, lat: 57.6682668},
    Postcode: 'AB44',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Macduff',
    postcode: 'AB44',
  },
  {
    coordinates: {lon: -2.5676286, lat: 57.6517031},
    Postcode: 'AB45',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Banff',
    postcode: 'AB45',
  },
  {
    coordinates: {lon: -2.403827, lat: 57.2876297},
    Postcode: 'AB51',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Inverurie',
    postcode: 'AB51',
  },
  {
    coordinates: {lon: -2.6088547, lat: 57.3437877},
    Postcode: 'AB52',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Insch',
    postcode: 'AB52',
  },
  {
    coordinates: {lon: -2.3937282, lat: 57.5250408},
    Postcode: 'AB53',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Turriff',
    postcode: 'AB53',
  },
  {
    coordinates: {lon: -2.761688, lat: 57.4540447},
    Postcode: 'AB54',
    Region: 'Aberdeenshire',
    'UK region': 'Scotland',
    Area: 'Aberchirder, Cabrach, Cairnie, Forgue, Gartly, Glass, Kennethmont, Lumsden, Rhynie, Rothiemay',
    postcode: 'AB54',
  },
  {
    coordinates: {lon: -2.9896438, lat: 57.5245238},
    Postcode: 'AB55',
    Region: 'Moray',
    'UK region': 'Scotland',
    Area: 'Keith',
    postcode: 'AB55',
  },
  {
    coordinates: {lon: -2.9288909, lat: 57.6743925},
    Postcode: 'AB56',
    Region: 'Moray',
    'UK region': 'Scotland',
    Area: 'Buckie',
    postcode: 'AB56',
  },
  {
    coordinates: {lon: -0.323756, lat: 51.7489129},
    Postcode: 'AL1',
    Region: 'St Albans',
    'UK region': 'East of England',
    Area: 'St Albans',
    postcode: 'AL1',
  },
  {
    coordinates: {lon: -0.2323864, lat: 51.7604054},
    Postcode: 'AL10',
    Region: 'Welwyn Hatfield',
    'UK region': 'East of England',
    Area: 'Hatfield',
    postcode: 'AL10',
  },
  {
    coordinates: {lon: -0.3354863, lat: 51.7191035},
    Postcode: 'AL2',
    Region: 'St Albans',
    'UK region': 'East of England',
    Area: 'St Albans, Bricket Wood, Colney Street, Frogmore, London Colney, Napsbury, Park Street, Potters Crouch, Chiswell Green',
    postcode: 'AL2',
  },
  {
    coordinates: {lon: -0.3775501, lat: 51.7803976},
    Postcode: 'AL3',
    Region: 'St Albans',
    'UK region': 'East of England',
    Area: 'St Albans, Childwickbury, Flamstead, Gorhambury, Markyate, Porters Wood, Redbourn, Sandridge',
    postcode: 'AL3',
  },
  {
    coordinates: {lon: -0.295024, lat: 51.7739838},
    Postcode: 'AL4',
    Region: 'St Albans',
    'UK region': 'East of England',
    Area: 'St Albans, Colney Heath, London Colney, Jersey Farm, Marshalswick, Oaklands, Sandridge, Smallford, Tyttenhanger, Wheathampstead',
    postcode: 'AL4',
  },
  {
    coordinates: {lon: -0.3520548, lat: 51.8153821},
    Postcode: 'AL5',
    Region: 'St Albans',
    'UK region': 'East of England',
    Area: 'Harpenden',
    postcode: 'AL5',
  },
  {
    coordinates: {lon: -0.1998286, lat: 51.832147},
    Postcode: 'AL6',
    Region: 'Welwyn Hatfield',
    'UK region': 'East of England',
    Area: 'Welwyn, Ayot St Peter, Ayot St Lawrence, Digswell, Rabley Heath, Tewin',
    postcode: 'AL6',
  },
  {
    coordinates: {lon: -0.1872378, lat: 51.7969028},
    Postcode: 'AL7',
    Region: 'Welwyn Hatfield',
    'UK region': 'East of England',
    Area: 'Welwyn Garden City',
    postcode: 'AL7',
  },
  {
    coordinates: {lon: -0.2117607, lat: 51.803623},
    Postcode: 'AL8',
    Region: 'Welwyn Hatfield',
    'UK region': 'East of England',
    Area: 'Welwyn Garden City, Lemsford',
    postcode: 'AL8',
  },
  {
    coordinates: {lon: -0.1976882, lat: 51.7445903},
    Postcode: 'AL9',
    Region: 'Welwyn Hatfield',
    'UK region': 'East of England',
    Area: 'Hatfield, Brookmans Park, Essendon, North Mymms',
    postcode: 'AL9',
  },
  {
    coordinates: {lon: -1.9091472, lat: 52.4799811},
    Postcode: 'B1',
    Region: 'Birmingham ',
    'UK region': 'West Midlands',
    Area: 'Birmingham City Centre, Ladywood',
    postcode: 'B1',
  },
  {
    coordinates: {lon: -1.8523465, lat: 52.4695926},
    Postcode: 'B10',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Small Heath',
    postcode: 'B10',
  },
  {
    coordinates: {lon: -1.8581324, lat: 52.4544936},
    Postcode: 'B11',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Sparkhill, Tyseley',
    postcode: 'B11',
  },
  {
    coordinates: {lon: -1.8836558, lat: 52.4607564},
    Postcode: 'B12',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Balsall Heath, Sparkbrook, Highgate',
    postcode: 'B12',
  },
  {
    coordinates: {lon: -1.8790028, lat: 52.4375032},
    Postcode: 'B13',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Moseley, Billesley',
    postcode: 'B13',
  },
  {
    coordinates: {lon: -1.8898132, lat: 52.4173564},
    Postcode: 'B14',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Kings Heath, Yardley Wood, Brandwood, Druids Heath, Warstock',
    postcode: 'B14',
  },
  {
    coordinates: {lon: -1.9233802, lat: 52.4661665},
    Postcode: 'B15',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Edgbaston, Lee Bank',
    postcode: 'B15',
  },
  {
    coordinates: {lon: -1.9342144, lat: 52.4771886},
    Postcode: 'B16',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Edgbaston, Ladywood',
    postcode: 'B16',
  },
  {
    coordinates: {lon: -1.9586781, lat: 52.461233},
    Postcode: 'B17',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Harborne',
    postcode: 'B17',
  },
  {
    coordinates: {lon: -1.9255863, lat: 52.4913461},
    Postcode: 'B18',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Winson Green, Hockley',
    postcode: 'B18',
  },
  {
    coordinates: {lon: -1.9057716, lat: 52.4971338},
    Postcode: 'B19',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Lozells, Newtown, Birchfield',
    postcode: 'B19',
  },
  {
    coordinates: {lon: -1.8965236, lat: 52.4893361},
    Postcode: 'B2',
    Region: 'Birmingham ',
    'UK region': 'West Midlands',
    Area: 'Birmingham City Centre, Ladywood',
    postcode: 'B2',
  },
  {
    coordinates: {lon: -1.9192015, lat: 52.515504},
    Postcode: 'B20',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Birchfield, Handsworth Wood Perry Barr',
    postcode: 'B20',
  },
  {
    coordinates: {lon: -1.9415679, lat: 52.506938},
    Postcode: 'B21',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Handsworth',
    postcode: 'B21',
  },
  {
    coordinates: {lon: -1.8553035, lat: 52.5285384},
    Postcode: 'B23',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Erdington, Short Heath',
    postcode: 'B23',
  },
  {
    coordinates: {lon: -1.8271375, lat: 52.5196415},
    Postcode: 'B24',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Erdington, Tyburn',
    postcode: 'B24',
  },
  {
    coordinates: {lon: -1.8219999, lat: 52.4649901},
    Postcode: 'B25',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Yardley',
    postcode: 'B25',
  },
  {
    coordinates: {lon: -1.7887486, lat: 52.4615675},
    Postcode: 'B26',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Sheldon, Yardley',
    postcode: 'B26',
  },
  {
    coordinates: {lon: -1.8228123, lat: 52.4445575},
    Postcode: 'B27',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Acocks Green',
    postcode: 'B27',
  },
  {
    coordinates: {lon: -1.8432114, lat: 52.4272318},
    Postcode: 'B28',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Hall Green',
    postcode: 'B28',
  },
  {
    coordinates: {lon: -1.947722, lat: 52.4376384},
    Postcode: 'B29',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Selly Oak, Bournbrook, Selly Park, Weoley Castle, California',
    postcode: 'B29',
  },
  {
    coordinates: {lon: -1.9024305, lat: 52.4850123},
    Postcode: 'B3',
    Region: 'Birmingham ',
    'UK region': 'West Midlands',
    Area: 'Birmingham City Centre, Ladywood',
    postcode: 'B3',
  },
  {
    coordinates: {lon: -1.9277595, lat: 52.421961},
    Postcode: 'B30',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Bournville, Cotteridge, Stirchley',
    postcode: 'B30',
  },
  {
    coordinates: {lon: -1.9733848, lat: 52.4081108},
    Postcode: 'B31',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Northfield',
    postcode: 'B31',
  },
  {
    coordinates: {lon: -1.9945407, lat: 52.4486238},
    Postcode: 'B32',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Woodgate, Bartley Green, Quinton, California',
    postcode: 'B32',
  },
  {
    coordinates: {lon: -1.7884156, lat: 52.4802868},
    Postcode: 'B33',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Kitts Green, Stechford',
    postcode: 'B33',
  },
  {
    coordinates: {lon: -1.7817039, lat: 52.4964133},
    Postcode: 'B34',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Shard End, Buckland End',
    postcode: 'B34',
  },
  {
    coordinates: {lon: -1.7890673, lat: 52.5188542},
    Postcode: 'B35',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Castle Vale',
    postcode: 'B35',
  },
  {
    coordinates: {lon: -1.7784585, lat: 52.5042044},
    Postcode: 'B36',
    Region: 'Solihull',
    'UK region': 'West Midlands',
    Area: "Castle Bromwich, Smith's Wood",
    postcode: 'B36',
  },
  {
    coordinates: {lon: -1.7421791, lat: 52.4784345},
    Postcode: 'B37',
    Region: 'Solihull',
    'UK region': 'West Midlands',
    Area: 'Chelmsley Wood, Marston Green, Kingshurst, Fordbridge',
    postcode: 'B37',
  },
  {
    coordinates: {lon: -1.9347197, lat: 52.3999018},
    Postcode: 'B38',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Kings Norton',
    postcode: 'B38',
  },
  {
    coordinates: {lon: -1.8928567, lat: 52.4857738},
    Postcode: 'B4',
    Region: 'Birmingham ',
    'UK region': 'West Midlands',
    Area: 'Birmingham City Centre, Ladywood',
    postcode: 'B4',
  },
  {
    coordinates: {lon: -1.7225262, lat: 52.4569283},
    Postcode: 'B40',
    Region: 'Solihull',
    'UK region': 'West Midlands',
    Area: 'National Exhibition Centre, Birmingham International Airport, Bickenhill',
    postcode: 'B40',
  },
  {
    coordinates: {lon: -1.9124526, lat: 52.5346593},
    Postcode: 'B42',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Perry Barr, Great Barr, Hamstead',
    postcode: 'B42',
  },
  {
    coordinates: {lon: -1.9307835, lat: 52.5485092},
    Postcode: 'B43',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Great Barr, Hamstead, Pheasey',
    postcode: 'B43',
  },
  {
    coordinates: {lon: -1.8850992, lat: 52.5456674},
    Postcode: 'B44',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Perry Barr, Kingstanding, Great Barr',
    postcode: 'B44',
  },
  {
    coordinates: {lon: -2.0090938, lat: 52.3885168},
    Postcode: 'B45',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Rednal, Rubery',
    postcode: 'B45',
  },
  {
    coordinates: {lon: -1.6949788, lat: 52.5088835},
    Postcode: 'B46',
    Region: 'North Warwickshire',
    'UK region': 'West Midlands',
    Area: 'Water Orton, Coleshill',
    postcode: 'B46',
  },
  {
    coordinates: {lon: -1.8800827, lat: 52.3852897},
    Postcode: 'B47',
    Region: 'Bromsgrove Worcestershire',
    'UK region': 'West Midlands',
    Area: 'Hollywood, Wythall',
    postcode: 'B47',
  },
  {
    coordinates: {lon: -1.9470221, lat: 52.3547623},
    Postcode: 'B48',
    Region: 'Bromsgrove Worcestershire',
    'UK region': 'West Midlands',
    Area: 'Alvechurch',
    postcode: 'B48',
  },
  {
    coordinates: {lon: -1.866774, lat: 52.2178973},
    Postcode: 'B49',
    Region: 'Stratford-on-Avon',
    'UK region': 'West Midlands',
    Area: 'Alcester',
    postcode: 'B49',
  },
  {
    coordinates: {lon: -1.8975868, lat: 52.4694163},
    Postcode: 'B5',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Digbeth, Highgate, Lee Bank',
    postcode: 'B5',
  },
  {
    coordinates: {lon: -1.8562373, lat: 52.1683483},
    Postcode: 'B50',
    Region: 'Stratford-on-Avon',
    'UK region': 'West Midlands',
    Area: 'Alcester',
    postcode: 'B50',
  },
  {
    coordinates: {lon: -1.8860786, lat: 52.5051401},
    Postcode: 'B6',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Aston',
    postcode: 'B6',
  },
  {
    coordinates: {lon: -2.0491825, lat: 52.3240959},
    Postcode: 'B60',
    Region: 'Bromsgrove',
    'UK region': 'West Midlands',
    Area: 'Bromsgrove',
    postcode: 'B60',
  },
  {
    coordinates: {lon: -2.068307, lat: 52.3464135},
    Postcode: 'B61',
    Region: 'Bromsgrove',
    'UK region': 'West Midlands',
    Area: 'Bromsgrove',
    postcode: 'B61',
  },
  {
    coordinates: {lon: -2.0337937, lat: 52.4571593},
    Postcode: 'B62',
    Region: 'Dudley',
    'UK region': 'West Midlands',
    Area: 'Halesowen',
    postcode: 'B62',
  },
  {
    coordinates: {lon: -2.0699158, lat: 52.4514517},
    Postcode: 'B63',
    Region: 'Dudley',
    'UK region': 'West Midlands',
    Area: 'Halesowen',
    postcode: 'B63',
  },
  {
    coordinates: {lon: -2.0694635, lat: 52.4725264},
    Postcode: 'B64',
    Region: 'Sandwell',
    'UK region': 'West Midlands',
    Area: 'Cradley Heath',
    postcode: 'B64',
  },
  {
    coordinates: {lon: -2.0437814, lat: 52.4836373},
    Postcode: 'B65',
    Region: 'Sandwell',
    'UK region': 'West Midlands',
    Area: 'Rowley Regis',
    postcode: 'B65',
  },
  {
    coordinates: {lon: -1.9647632, lat: 52.4926661},
    Postcode: 'B66',
    Region: 'Sandwell',
    'UK region': 'West Midlands',
    Area: 'Smethwick, Bearwood',
    postcode: 'B66',
  },
  {
    coordinates: {lon: -1.9787293, lat: 52.485705},
    Postcode: 'B67',
    Region: 'Sandwell',
    'UK region': 'West Midlands',
    Area: 'Smethwick, Bearwood',
    postcode: 'B67',
  },
  {
    coordinates: {lon: -2.0014018, lat: 52.4785994},
    Postcode: 'B68',
    Region: 'Sandwell',
    'UK region': 'West Midlands',
    Area: 'Oldbury',
    postcode: 'B68',
  },
  {
    coordinates: {lon: -2.030311, lat: 52.5027111},
    Postcode: 'B69',
    Region: 'Sandwell',
    'UK region': 'West Midlands',
    Area: 'Oldbury',
    postcode: 'B69',
  },
  {
    coordinates: {lon: -1.8723775, lat: 52.4941549},
    Postcode: 'B7',
    Region: 'Birmingham',
    'UK region': 'West Midlands',
    Area: 'Nechells',
    postcode: 'B7',
  },
  {
    coordinates: {lon: -2.0044143, lat: 52.5209224},
    Postcode: 'B70',
    Region: 'Sandwell',
    'UK region': 'West Midlands',
    Area: 'West Bromwich',
    postcode: 'B70',
  },
  {
    coordinates: {lon: -1.9904942, lat: 52.5360066},
    Postcode: 'B71',
    Region: 'Sandwell',
    'UK region': 'West Midlands',
    Area: 'West Bromwich',
    postcode: 'B71',
  },
];
