export const uksic = [
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d05',
    },
    id: '10',
    'SIC Code': '1230',
    Label: 'Growing of citrus fruits',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d13',
    },
    id: '24',
    'SIC Code': '1470',
    Label: 'Raising of poultry',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d06',
    },
    id: '11',
    'SIC Code': '1240',
    Label: 'Growing of pome fruits and stone fruits',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986cfe',
    },
    id: '3',
    'SIC Code': '1130',
    Label: 'Growing of vegetables and melons, roots and tubers',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d09',
    },
    id: '14',
    'SIC Code': '1270',
    Label: 'Growing of beverage crops',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d1d',
    },
    id: '34',
    'SIC Code': '2200',
    Label: 'Logging',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986cfd',
    },
    id: '2',
    'SIC Code': '1120',
    Label: 'Growing of rice',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d07',
    },
    id: '12',
    'SIC Code': '1250',
    Label: 'Growing of other tree and bush fruits and nuts',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d16',
    },
    id: '27',
    'SIC Code': '1610',
    Label: 'Support activities for crop production',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d1a',
    },
    id: '31',
    'SIC Code': '1640',
    Label: 'Seed processing for propagation',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d33',
    },
    id: '56',
    'SIC Code': '9900',
    Label: 'Support activities for other mining and quarrying',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d42',
    },
    id: '71',
    'SIC Code': '10612',
    Label: 'Manufacture of breakfast cereals and cereals-based food',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d65',
    },
    id: '106',
    'SIC Code': '13960',
    Label: 'Manufacture of other technical and industrial textiles',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d6b',
    },
    id: '112',
    'SIC Code': '14141',
    Label: "Manufacture of men's underwear",
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d71',
    },
    id: '118',
    'SIC Code': '15110',
    Label: 'Tanning and dressing of leather; dressing and dyeing of fur',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d89',
    },
    id: '142',
    'SIC Code': '18203',
    Label: 'Reproduction of computer media',
    Categories: 'Software',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d8e',
    },
    id: '147',
    'SIC Code': '20120',
    Label: 'Manufacture of dyes and pigments',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dae',
    },
    id: '179',
    'SIC Code': '23320',
    Label: 'Manufacture of bricks, tiles and construction products, in baked clay',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986db0',
    },
    id: '181',
    'SIC Code': '23420',
    Label: 'Manufacture of ceramic sanitary fixtures',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986db5',
    },
    id: '186',
    'SIC Code': '23520',
    Label: 'Manufacture of lime and plaster',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986de2',
    },
    id: '231',
    'SIC Code': '26200',
    Label: 'Manufacture of computers and peripheral equipment',
    Categories: 'Computers',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dea',
    },
    id: '239',
    'SIC Code': '26520',
    Label: 'Manufacture of watches and clocks',
    Categories: 'Fashion',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986deb',
    },
    id: '240',
    'SIC Code': '26600',
    Label: 'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dec',
    },
    id: '241',
    'SIC Code': '26701',
    Label: 'Manufacture of optical precision instruments',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986df3',
    },
    id: '248',
    'SIC Code': '27320',
    Label: 'Manufacture of other electronic and electric wires and cables',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e04',
    },
    id: '265',
    'SIC Code': '28290',
    Label: 'Manufacture of other general-purpose machinery n.e.c.',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e0d',
    },
    id: '274',
    'SIC Code': '28930',
    Label: 'Manufacture of machinery for food, beverage and tobacco processing',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e11',
    },
    id: '278',
    'SIC Code': '28990',
    Label: 'Manufacture of other special-purpose machinery n.e.c.',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e15',
    },
    id: '282',
    'SIC Code': '29203',
    Label: 'Manufacture of caravans',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e18',
    },
    id: '285',
    'SIC Code': '30110',
    Label: 'Building of ships and floating structures',
    Categories: 'Maritime, Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e44',
    },
    id: '329',
    'SIC Code': '38220',
    Label: 'Treatment and disposal of hazardous waste',
    Categories: 'Environment',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e53',
    },
    id: '344',
    'SIC Code': '43120',
    Label: 'Site preparation',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e57',
    },
    id: '348',
    'SIC Code': '43290',
    Label: 'Other construction installation',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e5d',
    },
    id: '354',
    'SIC Code': '43390',
    Label: 'Other building completion and finishing',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e73',
    },
    id: '376',
    'SIC Code': '46230',
    Label: 'Wholesale of live animals',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e74',
    },
    id: '377',
    'SIC Code': '46240',
    Label: 'Wholesale of hides, skins and leather',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e93',
    },
    id: '408',
    'SIC Code': '46711',
    Label: 'Wholesale of petroleum and petroleum products',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e97',
    },
    id: '412',
    'SIC Code': '46740',
    Label: 'Wholesale of hardware, plumbing and heating equipment and supplies',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eb5',
    },
    id: '442',
    'SIC Code': '47710',
    Label: 'Retail sale of clothing in specialised stores',
    Categories: 'Fashion, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ebd',
    },
    id: '450',
    'SIC Code': '47770',
    Label: 'Retail sale of watches and jewellery in specialised stores',
    Categories: 'Fashion, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ecc',
    },
    id: '465',
    'SIC Code': '49320',
    Label: 'Taxi operation',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ef2',
    },
    id: '503',
    'SIC Code': '56210',
    Label: 'Event catering activities',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f0b',
    },
    id: '528',
    'SIC Code': '61300',
    Label: 'Satellite telecommunications activities',
    Categories: 'Communications',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f1a',
    },
    id: '543',
    'SIC Code': '64202',
    Label: 'Activities of production holding companies',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f2e',
    },
    id: '563',
    'SIC Code': '65201',
    Label: 'Life reinsurance',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f32',
    },
    id: '567',
    'SIC Code': '66120',
    Label: 'Security and commodity contracts dealing activities',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f33',
    },
    id: '568',
    'SIC Code': '66190',
    Label: 'Activities auxiliary to financial intermediation n.e.c.',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f4e',
    },
    id: '595',
    'SIC Code': '72110',
    Label: 'Research and experimental development on biotechnology',
    Categories: 'Medical, Software',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f57',
    },
    id: '604',
    'SIC Code': '74203',
    Label: 'Film processing',
    Categories: 'Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f72',
    },
    id: '631',
    'SIC Code': '79110',
    Label: 'Travel agency activities',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f74',
    },
    id: '633',
    'SIC Code': '79901',
    Label: 'Activities of tourist guides',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fa6',
    },
    id: '683',
    'SIC Code': '87100',
    Label: 'Residential nursing care facilities',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d0e',
    },
    id: '19',
    'SIC Code': '1420',
    Label: 'Raising of other cattle and buffaloes',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986cff',
    },
    id: '4',
    'SIC Code': '1140',
    Label: 'Growing of sugar cane',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d04',
    },
    id: '9',
    'SIC Code': '1220',
    Label: 'Growing of tropical and subtropical fruits',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d0a',
    },
    id: '15',
    'SIC Code': '1280',
    Label: 'Growing of spices, aromatic, drug and pharmaceutical crops',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d25',
    },
    id: '42',
    'SIC Code': '5102',
    Label: 'Open cast coal working',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d26',
    },
    id: '43',
    'SIC Code': '5200',
    Label: 'Mining of lignite',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d40',
    },
    id: '69',
    'SIC Code': '10520',
    Label: 'Manufacture of ice cream',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d5b',
    },
    id: '96',
    'SIC Code': '13200',
    Label: 'Weaving of textiles',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d61',
    },
    id: '102',
    'SIC Code': '13931',
    Label: 'Manufacture of woven or tufted carpets and rugs',
    Categories: 'Interior Design, Household',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d7c',
    },
    id: '129',
    'SIC Code': '17211',
    Label: 'Manufacture of corrugated paper and paperboard, sacks and bags',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d8d',
    },
    id: '146',
    'SIC Code': '20110',
    Label: 'Manufacture of industrial gases',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d9c',
    },
    id: '161',
    'SIC Code': '20530',
    Label: 'Manufacture of essential oils',
    Categories: 'Beauty, Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986da0',
    },
    id: '165',
    'SIC Code': '21200',
    Label: 'Manufacture of pharmaceutical preparations',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986da5',
    },
    id: '170',
    'SIC Code': '22230',
    Label: 'Manufacture of builders ware of plastic',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986db2',
    },
    id: '183',
    'SIC Code': '23440',
    Label: 'Manufacture of other technical ceramic products',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d17',
    },
    id: '28',
    'SIC Code': '1621',
    Label: 'Farm animal boarding and care',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d2d',
    },
    id: '50',
    'SIC Code': '8120',
    Label: 'Operation of gravel and sand pits; mining of clays and kaolin',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dc2',
    },
    id: '199',
    'SIC Code': '24320',
    Label: 'Cold rolling of narrow strip',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dc5',
    },
    id: '202',
    'SIC Code': '24410',
    Label: 'Precious metals production',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d31',
    },
    id: '54',
    'SIC Code': '8990',
    Label: 'Other mining and quarrying n.e.c.',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d76',
    },
    id: '123',
    'SIC Code': '16220',
    Label: 'Manufacture of assembled parquet floors',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dd4',
    },
    id: '217',
    'SIC Code': '25400',
    Label: 'Manufacture of weapons and ammunition',
    Categories: 'Defense',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dd5',
    },
    id: '218',
    'SIC Code': '25500',
    Label: 'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d79',
    },
    id: '126',
    'SIC Code': '16290',
    Label:
      'Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dfc',
    },
    id: '257',
    'SIC Code': '28132',
    Label: 'Manufacture of compressors',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d7b',
    },
    id: '128',
    'SIC Code': '17120',
    Label: 'Manufacture of paper and paperboard',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e05',
    },
    id: '266',
    'SIC Code': '28301',
    Label: 'Manufacture of agricultural tractors',
    Categories: 'Farming, Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dc8',
    },
    id: '205',
    'SIC Code': '24440',
    Label: 'Copper production',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e20',
    },
    id: '293',
    'SIC Code': '31010',
    Label: 'Manufacture of office and shop furniture',
    Categories: 'Interior Design, Furniture',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dcb',
    },
    id: '208',
    'SIC Code': '24510',
    Label: 'Casting of iron',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e56',
    },
    id: '347',
    'SIC Code': '43220',
    Label: 'Plumbing, heat and air-conditioning installation',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dd8',
    },
    id: '221',
    'SIC Code': '25710',
    Label: 'Manufacture of cutlery',
    Categories: 'Products, Kitchenware',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e5a',
    },
    id: '351',
    'SIC Code': '43330',
    Label: 'Floor and wall covering',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e0e',
    },
    id: '275',
    'SIC Code': '28940',
    Label: 'Manufacture of machinery for textile, apparel and leather production',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e5f',
    },
    id: '356',
    'SIC Code': '43991',
    Label: 'Scaffold erection',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e16',
    },
    id: '283',
    'SIC Code': '29310',
    Label:
      'Manufacture of electrical and electronic equipment for motor vehicles and their engines',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e88',
    },
    id: '397',
    'SIC Code': '46491',
    Label: 'Wholesale of musical instruments',
    Categories: 'Music, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e23',
    },
    id: '296',
    'SIC Code': '31090',
    Label: 'Manufacture of other furniture',
    Categories: 'Interior Design, Furniture',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e8b',
    },
    id: '400',
    'SIC Code': '46520',
    Label: 'Wholesale of electronic and telecommunications equipment and parts',
    Categories: 'Electronics, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e2a',
    },
    id: '303',
    'SIC Code': '32409',
    Label: 'Manufacture of other games and toys, n.e.c.',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ea0',
    },
    id: '421',
    'SIC Code': '47230',
    Label: 'Retail sale of fish, crustaceans and molluscs in specialised stores',
    Categories: 'Food, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e3b',
    },
    id: '320',
    'SIC Code': '35210',
    Label: 'Manufacture of gas',
    Categories: 'Energy',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ea5',
    },
    id: '426',
    'SIC Code': '47300',
    Label: 'Retail sale of automotive fuel in specialised stores',
    Categories: 'Energy, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eb9',
    },
    id: '446',
    'SIC Code': '47741',
    Label: 'Retail sale of hearing aids',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e54',
    },
    id: '345',
    'SIC Code': '43130',
    Label: 'Test drilling and boring',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e8d',
    },
    id: '402',
    'SIC Code': '46620',
    Label: 'Wholesale of machine tools',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ebb',
    },
    id: '448',
    'SIC Code': '47750',
    Label: 'Retail sale of cosmetic and toilet articles in specialised stores',
    Categories: 'Beauty, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ef0',
    },
    id: '501',
    'SIC Code': '56102',
    Label: 'Unlicensed restaurants and cafes',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e8f',
    },
    id: '404',
    'SIC Code': '46640',
    Label: 'Wholesale of machinery for the textile industry and of sewing and knitting machines',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f04',
    },
    id: '521',
    'SIC Code': '59133',
    Label: 'Television programme distribution activities',
    Categories: 'Film, Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e91',
    },
    id: '406',
    'SIC Code': '46660',
    Label: 'Wholesale of other office machinery and equipment',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f05',
    },
    id: '522',
    'SIC Code': '59140',
    Label: 'Motion picture projection activities',
    Categories: 'Film, Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ea1',
    },
    id: '422',
    'SIC Code': '47240',
    Label:
      'Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores',
    Categories: 'Food, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f08',
    },
    id: '525',
    'SIC Code': '60200',
    Label: 'Television programming and broadcasting activities',
    Categories: 'Film, Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ec5',
    },
    id: '458',
    'SIC Code': '47890',
    Label: 'Retail sale via stalls and markets of other goods',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f0c',
    },
    id: '529',
    'SIC Code': '61900',
    Label: 'Other telecommunications activities',
    Categories: 'Communications',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ec9',
    },
    id: '462',
    'SIC Code': '49200',
    Label: 'Freight rail transport',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f17',
    },
    id: '540',
    'SIC Code': '64191',
    Label: 'Banks',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ecf',
    },
    id: '468',
    'SIC Code': '49420',
    Label: 'Removal services',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f1f',
    },
    id: '548',
    'SIC Code': '64301',
    Label: 'Activities of investment trusts',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ed2',
    },
    id: '471',
    'SIC Code': '50200',
    Label: 'Sea and coastal freight water transport',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f58',
    },
    id: '605',
    'SIC Code': '74209',
    Label: 'Photographic activities not elsewhere classified',
    Categories: 'Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ed4',
    },
    id: '473',
    'SIC Code': '50400',
    Label: 'Inland freight water transport',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f7b',
    },
    id: '640',
    'SIC Code': '81221',
    Label: 'Window cleaning services',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f9f',
    },
    id: '676',
    'SIC Code': '85600',
    Label: 'Educational support services',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986edd',
    },
    id: '482',
    'SIC Code': '52212',
    Label: 'Operation of rail passenger facilities at railway stations',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fa8',
    },
    id: '685',
    'SIC Code': '87300',
    Label: 'Residential care activities for the elderly and disabled',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986edf',
    },
    id: '484',
    'SIC Code': '52219',
    Label: 'Other service activities incidental to land transportation, n.e.c.',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fb1',
    },
    id: '694',
    'SIC Code': '91011',
    Label: 'Library activities',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f0f',
    },
    id: '532',
    'SIC Code': '62020',
    Label: 'Information technology consultancy activities',
    Categories: 'IT, Software',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f18',
    },
    id: '541',
    'SIC Code': '64192',
    Label: 'Building societies',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f25',
    },
    id: '554',
    'SIC Code': '64910',
    Label: 'Financial leasing',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f2b',
    },
    id: '560',
    'SIC Code': '64999',
    Label: 'Financial intermediation not elsewhere classified',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f4c',
    },
    id: '593',
    'SIC Code': '71129',
    Label: 'Other engineering activities',
    Categories: 'Construction, Building',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f5b',
    },
    id: '608',
    'SIC Code': '74902',
    Label: 'Quantity surveying activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f68',
    },
    id: '621',
    'SIC Code': '77341',
    Label: 'Renting and leasing of passenger water transport equipment',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f6a',
    },
    id: '623',
    'SIC Code': '77351',
    Label: 'Renting and leasing of air passenger transport equipment',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f75',
    },
    id: '634',
    'SIC Code': '79909',
    Label: 'Other reservation service activities n.e.c.',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f85',
    },
    id: '650',
    'SIC Code': '82301',
    Label: 'Activities of exhibition and fair organisers',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f8a',
    },
    id: '655',
    'SIC Code': '82990',
    Label: 'Other business support service activities n.e.c.',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f99',
    },
    id: '670',
    'SIC Code': '85421',
    Label: 'First-degree level higher education',
    Categories: 'University',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fa5',
    },
    id: '682',
    'SIC Code': '86900',
    Label: 'Other human health activities',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fc7',
    },
    id: '716',
    'SIC Code': '95220',
    Label: 'Repair of household appliances and home and garden equipment',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fd1',
    },
    id: '726',
    'SIC Code': '97000',
    Label: 'Activities of households as employers of domestic personnel',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d2e',
    },
    id: '51',
    'SIC Code': '8910',
    Label: 'Mining of chemical and fertilizer minerals',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d56',
    },
    id: '91',
    'SIC Code': '11050',
    Label: 'Manufacture of beer',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d6a',
    },
    id: '111',
    'SIC Code': '14132',
    Label: "Manufacture of other women's outerwear",
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d8b',
    },
    id: '144',
    'SIC Code': '19201',
    Label: 'Mineral oil refining',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d97',
    },
    id: '156',
    'SIC Code': '20411',
    Label: 'Manufacture of soap and detergents',
    Categories: 'Beauty',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986da3',
    },
    id: '168',
    'SIC Code': '22210',
    Label: 'Manufacture of plastic plates, sheets, tubes and profiles',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986db3',
    },
    id: '184',
    'SIC Code': '23490',
    Label: 'Manufacture of other ceramic products n.e.c.',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dc7',
    },
    id: '204',
    'SIC Code': '24430',
    Label: 'Lead, zinc and tin production',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dc9',
    },
    id: '206',
    'SIC Code': '24450',
    Label: 'Other non-ferrous metal production',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dd2',
    },
    id: '215',
    'SIC Code': '25290',
    Label: 'Manufacture of other tanks, reservoirs and containers of metal',
    Categories: 'Building',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986df2',
    },
    id: '247',
    'SIC Code': '27310',
    Label: 'Manufacture of fibre optic cables',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e12',
    },
    id: '279',
    'SIC Code': '29100',
    Label: 'Manufacture of motor vehicles',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e33',
    },
    id: '312',
    'SIC Code': '33160',
    Label: 'Repair and maintenance of aircraft and spacecraft',
    Categories: 'Aerospace, Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e46',
    },
    id: '331',
    'SIC Code': '38320',
    Label: 'Recovery of sorted materials',
    Categories: 'Environment',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e71',
    },
    id: '374',
    'SIC Code': '46210',
    Label: 'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e95',
    },
    id: '410',
    'SIC Code': '46720',
    Label: 'Wholesale of metals and metal ores',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eb2',
    },
    id: '439',
    'SIC Code': '47630',
    Label: 'Retail sale of music and video recordings in specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986edb',
    },
    id: '480',
    'SIC Code': '52103',
    Label: 'Operation of warehousing and storage facilities for land transport activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f27',
    },
    id: '556',
    'SIC Code': '64922',
    Label: 'Activities of mortgage finance companies',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f36',
    },
    id: '571',
    'SIC Code': '66290',
    Label: 'Other activities auxiliary to insurance and pension funding',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f42',
    },
    id: '583',
    'SIC Code': '69202',
    Label: 'Bookkeeping activities',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f46',
    },
    id: '587',
    'SIC Code': '70221',
    Label: 'Financial management',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f4b',
    },
    id: '592',
    'SIC Code': '71122',
    Label: 'Engineering related scientific and technical consulting activities',
    Categories: 'Construction, Building',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f4f',
    },
    id: '596',
    'SIC Code': '72190',
    Label: 'Other research and experimental development on natural sciences and engineering',
    Categories: 'Medical, Software',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f60',
    },
    id: '613',
    'SIC Code': '77120',
    Label: 'Renting and leasing of trucks and other heavy vehicles',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f6c',
    },
    id: '625',
    'SIC Code': '77390',
    Label: 'Renting and leasing of other machinery, equipment and tangible goods n.e.c.',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f78',
    },
    id: '637',
    'SIC Code': '80300',
    Label: 'Investigation activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f82',
    },
    id: '647',
    'SIC Code': '82110',
    Label: 'Combined office administrative service activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f91',
    },
    id: '662',
    'SIC Code': '84240',
    Label: 'Public order and safety activities',
    Categories: 'Legal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f94',
    },
    id: '665',
    'SIC Code': '85100',
    Label: 'Pre-primary education',
    Categories: 'Schools',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f95',
    },
    id: '666',
    'SIC Code': '85200',
    Label: 'Primary education',
    Categories: 'Schools',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f9a',
    },
    id: '671',
    'SIC Code': '85422',
    Label: 'Post-graduate level higher education',
    Categories: 'University',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f9d',
    },
    id: '674',
    'SIC Code': '85530',
    Label: 'Driving school activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fb6',
    },
    id: '699',
    'SIC Code': '92000',
    Label: 'Gambling and betting activities',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fcc',
    },
    id: '721',
    'SIC Code': '96010',
    Label: 'Washing and (dry-)cleaning of textile and fur products',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d22',
    },
    id: '39',
    'SIC Code': '3210',
    Label: 'Marine aquaculture',
    Categories: 'Fishing',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d24',
    },
    id: '41',
    'SIC Code': '5101',
    Label: 'Deep coal mines',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d2a',
    },
    id: '47',
    'SIC Code': '7210',
    Label: 'Mining of uranium and thorium ores',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d2b',
    },
    id: '48',
    'SIC Code': '7290',
    Label: 'Mining of other non-ferrous metal ores',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d36',
    },
    id: '59',
    'SIC Code': '10130',
    Label: 'Production of meat and poultry meat products',
    Categories: 'Food, Butcher',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d3c',
    },
    id: '65',
    'SIC Code': '10420',
    Label: 'Manufacture of margarine and similar edible fats',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d45',
    },
    id: '74',
    'SIC Code': '10720',
    Label: 'Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d46',
    },
    id: '75',
    'SIC Code': '10730',
    Label: 'Manufacture of macaroni, noodles, couscous and similar farinaceous products',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d4f',
    },
    id: '84',
    'SIC Code': '10890',
    Label: 'Manufacture of other food products n.e.c.',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d50',
    },
    id: '85',
    'SIC Code': '10910',
    Label: 'Manufacture of prepared feeds for farm animals',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d5f',
    },
    id: '100',
    'SIC Code': '13922',
    Label: 'manufacture of canvas goods, sacks, etc.',
    Categories: 'Interior Design, Household',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d60',
    },
    id: '101',
    'SIC Code': '13923',
    Label: 'manufacture of household textiles',
    Categories: 'Interior Design, Household',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d6e',
    },
    id: '115',
    'SIC Code': '14200',
    Label: 'Manufacture of articles of fur',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d72',
    },
    id: '119',
    'SIC Code': '15120',
    Label: 'Manufacture of luggage, handbags and the like, saddlery and harness',
    Categories: 'Fashion, Suitcase',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d73',
    },
    id: '120',
    'SIC Code': '15200',
    Label: 'Manufacture of footwear',
    Categories: 'Fashion, Shoes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d85',
    },
    id: '138',
    'SIC Code': '18130',
    Label: 'Pre-press and pre-media services',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d8c',
    },
    id: '145',
    'SIC Code': '19209',
    Label: 'Other treatment of petroleum products (excluding petrochemicals manufacture)',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986da1',
    },
    id: '166',
    'SIC Code': '22110',
    Label: 'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986de3',
    },
    id: '232',
    'SIC Code': '26301',
    Label: 'Manufacture of telegraph and telephone apparatus and equipment',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986de5',
    },
    id: '234',
    'SIC Code': '26400',
    Label: 'Manufacture of consumer electronics',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e0c',
    },
    id: '273',
    'SIC Code': '28923',
    Label: 'Manufacture of equipment for concrete crushing and screening and roadworks',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e1c',
    },
    id: '289',
    'SIC Code': '30400',
    Label: 'Manufacture of military fighting vehicles',
    Categories: 'Defense, Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e26',
    },
    id: '299',
    'SIC Code': '32130',
    Label: 'Manufacture of imitation jewellery and related articles',
    Categories: 'Fashion',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e2c',
    },
    id: '305',
    'SIC Code': '32910',
    Label: 'Manufacture of brooms and brushes',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e3d',
    },
    id: '322',
    'SIC Code': '35230',
    Label: 'Trade of gas through mains',
    Categories: 'Utitlies, Energy',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e4a',
    },
    id: '335',
    'SIC Code': '41202',
    Label: 'Construction of domestic buildings',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e81',
    },
    id: '390',
    'SIC Code': '46431',
    Label:
      'Wholesale of audio tapes, records, CDs and video tapes and the equipment on which these are played',
    Categories: 'Electronics, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e96',
    },
    id: '411',
    'SIC Code': '46730',
    Label: 'Wholesale of wood, construction materials and sanitary equipment',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ea2',
    },
    id: '423',
    'SIC Code': '47250',
    Label: 'Retail sale of beverages in specialised stores',
    Categories: 'Food, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eae',
    },
    id: '435',
    'SIC Code': '47591',
    Label: 'Retail sale of musical instruments and scores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ee2',
    },
    id: '487',
    'SIC Code': '52241',
    Label: 'Cargo handling for water transport activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ef4',
    },
    id: '505',
    'SIC Code': '56301',
    Label: 'Licensed clubs',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f0a',
    },
    id: '527',
    'SIC Code': '61200',
    Label: 'Wireless telecommunications activities',
    Categories: 'Communications',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f13',
    },
    id: '536',
    'SIC Code': '63120',
    Label: 'Web portals',
    Categories: 'IT',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f15',
    },
    id: '538',
    'SIC Code': '63990',
    Label: 'Other information service activities n.e.c.',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f1d',
    },
    id: '546',
    'SIC Code': '64205',
    Label: 'Activities of financial services holding companies',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f3e',
    },
    id: '579',
    'SIC Code': '69101',
    Label: 'Barristers at law',
    Categories: 'Legal, Lawyers',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f89',
    },
    id: '654',
    'SIC Code': '82920',
    Label: 'Packaging activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f8c',
    },
    id: '657',
    'SIC Code': '84120',
    Label:
      'Regulation of health care, education, cultural and other social services, not incl. social security',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f92',
    },
    id: '663',
    'SIC Code': '84250',
    Label: 'Fire service activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f96',
    },
    id: '667',
    'SIC Code': '85310',
    Label: 'General secondary education',
    Categories: 'Schools',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f9e',
    },
    id: '675',
    'SIC Code': '85590',
    Label: 'Other education n.e.c.',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fb8',
    },
    id: '701',
    'SIC Code': '93120',
    Label: 'Activities of sport clubs',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d0b',
    },
    id: '16',
    'SIC Code': '1290',
    Label: 'Growing of other perennial crops',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d48',
    },
    id: '77',
    'SIC Code': '10821',
    Label: 'Manufacture of cocoa and chocolate confectionery',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d49',
    },
    id: '78',
    'SIC Code': '10822',
    Label: 'Manufacture of sugar confectionery',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d58',
    },
    id: '93',
    'SIC Code': '11070',
    Label: 'Manufacture of soft drinks; production of mineral waters and other bottled waters',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d5d',
    },
    id: '98',
    'SIC Code': '13910',
    Label: 'Manufacture of knitted and crocheted fabrics',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d81',
    },
    id: '134',
    'SIC Code': '17290',
    Label: 'Manufacture of other articles of paper and paperboard n.e.c.',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d82',
    },
    id: '135',
    'SIC Code': '18110',
    Label: 'Printing of newspapers',
    Categories: 'Journalism, Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d9a',
    },
    id: '159',
    'SIC Code': '20510',
    Label: 'Manufacture of explosives',
    Categories: 'Defense, Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986da6',
    },
    id: '171',
    'SIC Code': '22290',
    Label: 'Manufacture of other plastic products',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dd3',
    },
    id: '216',
    'SIC Code': '25300',
    Label: 'Manufacture of steam generators, except central heating hot water boilers',
    Categories: 'Building',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dd7',
    },
    id: '220',
    'SIC Code': '25620',
    Label: 'Machining',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dd9',
    },
    id: '222',
    'SIC Code': '25720',
    Label: 'Manufacture of locks and hinges',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dda',
    },
    id: '223',
    'SIC Code': '25730',
    Label: 'Manufacture of tools',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986de1',
    },
    id: '230',
    'SIC Code': '26120',
    Label: 'Manufacture of loaded electronic boards',
    Categories: 'Computers',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ded',
    },
    id: '242',
    'SIC Code': '26702',
    Label: 'Manufacture of photographic and cinematographic equipment',
    Categories: 'Film, Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986df6',
    },
    id: '251',
    'SIC Code': '27510',
    Label: 'Manufacture of electric domestic appliances',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e02',
    },
    id: '263',
    'SIC Code': '28240',
    Label: 'Manufacture of power-driven hand tools',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e1d',
    },
    id: '290',
    'SIC Code': '30910',
    Label: 'Manufacture of motorcycles',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e25',
    },
    id: '298',
    'SIC Code': '32120',
    Label: 'Manufacture of jewellery and related articles',
    Categories: 'Fashion',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e36',
    },
    id: '315',
    'SIC Code': '33200',
    Label: 'Installation of industrial machinery and equipment',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e61',
    },
    id: '358',
    'SIC Code': '45111',
    Label: 'Sale of new cars and light motor vehicles',
    Categories: 'Transport, Cars',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e64',
    },
    id: '361',
    'SIC Code': '45200',
    Label: 'Maintenance and repair of motor vehicles',
    Categories: 'Transport, Cars',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e92',
    },
    id: '407',
    'SIC Code': '46690',
    Label: 'Wholesale of other machinery and equipment',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ea3',
    },
    id: '424',
    'SIC Code': '47260',
    Label: 'Retail sale of tobacco products in specialised stores',
    Categories: 'Food, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eac',
    },
    id: '433',
    'SIC Code': '47530',
    Label: 'Retail sale of carpets, rugs, wall and floor coverings in specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eb7',
    },
    id: '444',
    'SIC Code': '47722',
    Label: 'Retail sale of leather goods in specialised stores',
    Categories: 'Fashion, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eba',
    },
    id: '447',
    'SIC Code': '47749',
    Label:
      'Retail sale of medical and orthopaedic goods in specialised stores (not incl. hearing aids) n.e.c.',
    Categories: 'Medical, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ec1',
    },
    id: '454',
    'SIC Code': '47791',
    Label: 'Retail sale of antiques including antique books in stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ee5',
    },
    id: '490',
    'SIC Code': '52290',
    Label: 'Other transportation support activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ef9',
    },
    id: '510',
    'SIC Code': '58141',
    Label: 'Publishing of learned journals',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986efd',
    },
    id: '514',
    'SIC Code': '58290',
    Label: 'Other software publishing',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986efe',
    },
    id: '515',
    'SIC Code': '59111',
    Label: 'Motion picture production activities',
    Categories: 'Film, Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eff',
    },
    id: '516',
    'SIC Code': '59112',
    Label: 'Video production activities',
    Categories: 'Film, Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f07',
    },
    id: '524',
    'SIC Code': '60100',
    Label: 'Radio broadcasting',
    Categories: 'Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f09',
    },
    id: '526',
    'SIC Code': '61100',
    Label: 'Wired telecommunications activities',
    Categories: 'Communications',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f22',
    },
    id: '551',
    'SIC Code': '64304',
    Label: 'Activities of open-ended investment companies',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f3f',
    },
    id: '580',
    'SIC Code': '69102',
    Label: 'Solicitors',
    Categories: 'Legal, Lawyers',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f53',
    },
    id: '600',
    'SIC Code': '73200',
    Label: 'Market research and public opinion polling',
    Categories: 'Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f54',
    },
    id: '601',
    'SIC Code': '74100',
    Label: 'Specialised design activities',
    Categories: 'Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f65',
    },
    id: '618',
    'SIC Code': '77310',
    Label: 'Renting and leasing of agricultural machinery and equipment',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f70',
    },
    id: '629',
    'SIC Code': '78200',
    Label: 'Temporary employment agency activities',
    Categories: 'Recruitment',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f77',
    },
    id: '636',
    'SIC Code': '80200',
    Label: 'Security systems service activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f8b',
    },
    id: '656',
    'SIC Code': '84110',
    Label: 'General public administration activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f8d',
    },
    id: '658',
    'SIC Code': '84130',
    Label: 'Regulation of and contribution to more efficient operation of businesses',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fa3',
    },
    id: '680',
    'SIC Code': '86220',
    Label: 'Specialists medical practice activities',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fb9',
    },
    id: '702',
    'SIC Code': '93130',
    Label: 'Fitness facilities',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fba',
    },
    id: '703',
    'SIC Code': '93191',
    Label: 'Activities of racehorse owners',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fc1',
    },
    id: '710',
    'SIC Code': '94910',
    Label: 'Activities of religious organizations',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fc3',
    },
    id: '712',
    'SIC Code': '94990',
    Label: 'Activities of other membership organizations n.e.c.',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d1f',
    },
    id: '36',
    'SIC Code': '2400',
    Label: 'Support services to forestry',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d2f',
    },
    id: '52',
    'SIC Code': '8920',
    Label: 'Extraction of peat',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d30',
    },
    id: '53',
    'SIC Code': '8930',
    Label: 'Extraction of salt',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d32',
    },
    id: '55',
    'SIC Code': '9100',
    Label: 'Support activities for petroleum and natural gas extraction',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d3e',
    },
    id: '67',
    'SIC Code': '10512',
    Label: 'Butter and cheese production',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d64',
    },
    id: '105',
    'SIC Code': '13950',
    Label: 'Manufacture of non-wovens and articles made from non-wovens, except apparel',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d70',
    },
    id: '117',
    'SIC Code': '14390',
    Label: 'Manufacture of other knitted and crocheted apparel',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d80',
    },
    id: '133',
    'SIC Code': '17240',
    Label: 'Manufacture of wallpaper',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d91',
    },
    id: '150',
    'SIC Code': '20150',
    Label: 'Manufacture of fertilizers and nitrogen compounds',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d98',
    },
    id: '157',
    'SIC Code': '20412',
    Label: 'Manufacture of cleaning and polishing preparations',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d9d',
    },
    id: '162',
    'SIC Code': '20590',
    Label: 'Manufacture of other chemical products n.e.c.',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d9f',
    },
    id: '164',
    'SIC Code': '21100',
    Label: 'Manufacture of basic pharmaceutical products',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986db7',
    },
    id: '188',
    'SIC Code': '23620',
    Label: 'Manufacture of plaster products for construction purposes',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dbe',
    },
    id: '195',
    'SIC Code': '23990',
    Label: 'Manufacture of other non-metallic mineral products n.e.c.',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986de0',
    },
    id: '229',
    'SIC Code': '26110',
    Label: 'Manufacture of electronic components',
    Categories: 'Computers',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986de9',
    },
    id: '238',
    'SIC Code': '26514',
    Label: 'Manufacture of non-electronic industrial process control equipment',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dfa',
    },
    id: '255',
    'SIC Code': '28120',
    Label: 'Manufacture of fluid power equipment',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dfb',
    },
    id: '256',
    'SIC Code': '28131',
    Label: 'Manufacture of pumps',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dfe',
    },
    id: '259',
    'SIC Code': '28150',
    Label: 'Manufacture of bearings, gears, gearing and driving elements',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e01',
    },
    id: '262',
    'SIC Code': '28230',
    Label:
      'Manufacture of office machinery and equipment (except computers and peripheral equipment)',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e07',
    },
    id: '268',
    'SIC Code': '28410',
    Label: 'Manufacture of metal forming machinery',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e0a',
    },
    id: '271',
    'SIC Code': '28921',
    Label: 'Manufacture of machinery for mining',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e10',
    },
    id: '277',
    'SIC Code': '28960',
    Label: 'Manufacture of plastics and rubber machinery',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e13',
    },
    id: '280',
    'SIC Code': '29201',
    Label: 'Manufacture of bodies (coachwork) for motor vehicles (except caravans)',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e34',
    },
    id: '313',
    'SIC Code': '33170',
    Label: 'Repair and maintenance of other transport equipment n.e.c.',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e42',
    },
    id: '327',
    'SIC Code': '38120',
    Label: 'Collection of hazardous waste',
    Categories: 'Environment',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e45',
    },
    id: '330',
    'SIC Code': '38310',
    Label: 'Dismantling of wrecks',
    Categories: 'Environment',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e65',
    },
    id: '362',
    'SIC Code': '45310',
    Label: 'Wholesale trade of motor vehicle parts and accessories',
    Categories: 'Transport, Cars',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e66',
    },
    id: '363',
    'SIC Code': '45320',
    Label: 'Retail trade of motor vehicle parts and accessories',
    Categories: 'Transport, Cars',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e76',
    },
    id: '379',
    'SIC Code': '46320',
    Label: 'Wholesale of meat and meat products',
    Categories: 'Food, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e77',
    },
    id: '380',
    'SIC Code': '46330',
    Label: 'Wholesale of dairy products, eggs and edible oils and fats',
    Categories: 'Food, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e82',
    },
    id: '391',
    'SIC Code': '46439',
    Label:
      "Wholesale of radio, television goods & electrical household appliances (other than records, tapes, CD's & video tapes and the equipment used for playing them)",
    Categories: 'Electronics, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e9e',
    },
    id: '419',
    'SIC Code': '47210',
    Label: 'Retail sale of fruit and vegetables in specialised stores',
    Categories: 'Food, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eb1',
    },
    id: '438',
    'SIC Code': '47620',
    Label: 'Retail sale of newspapers and stationery in specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ec7',
    },
    id: '460',
    'SIC Code': '47990',
    Label: 'Other retail sale not in stores, stalls or markets',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f06',
    },
    id: '523',
    'SIC Code': '59200',
    Label: 'Sound recording and music publishing activities',
    Categories: 'Film, Music',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f0e',
    },
    id: '531',
    'SIC Code': '62012',
    Label: 'Business and domestic software development',
    Categories: 'Software',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f24',
    },
    id: '553',
    'SIC Code': '64306',
    Label: 'Activities of real estate investment trusts',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f28',
    },
    id: '557',
    'SIC Code': '64929',
    Label: 'Other credit granting n.e.c.',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f2a',
    },
    id: '559',
    'SIC Code': '64992',
    Label: 'Factoring',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f52',
    },
    id: '599',
    'SIC Code': '73120',
    Label: 'Media representation services',
    Categories: 'Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f79',
    },
    id: '638',
    'SIC Code': '81100',
    Label: 'Combined facilities support activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f7f',
    },
    id: '644',
    'SIC Code': '81291',
    Label: 'Disinfecting and exterminating services',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f80',
    },
    id: '645',
    'SIC Code': '81299',
    Label: 'Other cleaning services',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f90',
    },
    id: '661',
    'SIC Code': '84230',
    Label: 'Justice and judicial activities',
    Categories: 'Legal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fa7',
    },
    id: '684',
    'SIC Code': '87200',
    Label:
      'Residential care activities for learning difficulties, mental health and substance abuse',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fc2',
    },
    id: '711',
    'SIC Code': '94920',
    Label: 'Activities of political organizations',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fc8',
    },
    id: '717',
    'SIC Code': '95230',
    Label: 'Repair of footwear and leather goods',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d27',
    },
    id: '44',
    'SIC Code': '6100',
    Label: 'Extraction of crude petroleum',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d37',
    },
    id: '60',
    'SIC Code': '10200',
    Label: 'Processing and preserving of fish, crustaceans and molluscs',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d3a',
    },
    id: '63',
    'SIC Code': '10390',
    Label: 'Other processing and preserving of fruit and vegetables',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d3b',
    },
    id: '64',
    'SIC Code': '10410',
    Label: 'Manufacture of oils and fats',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d59',
    },
    id: '94',
    'SIC Code': '12000',
    Label: 'Manufacture of tobacco products',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d5a',
    },
    id: '95',
    'SIC Code': '13100',
    Label: 'Preparation and spinning of textile fibres',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d5c',
    },
    id: '97',
    'SIC Code': '13300',
    Label: 'Finishing of textiles',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d74',
    },
    id: '121',
    'SIC Code': '16100',
    Label: 'Sawmilling and planing of wood',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d78',
    },
    id: '125',
    'SIC Code': '16240',
    Label: 'Manufacture of wooden containers',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d90',
    },
    id: '149',
    'SIC Code': '20140',
    Label: 'Manufacture of other organic basic chemicals',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dac',
    },
    id: '177',
    'SIC Code': '23200',
    Label: 'Manufacture of refractory products',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dbc',
    },
    id: '193',
    'SIC Code': '23700',
    Label: 'Cutting, shaping and finishing of stone',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dbd',
    },
    id: '194',
    'SIC Code': '23910',
    Label: 'Production of abrasive products',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dc1',
    },
    id: '198',
    'SIC Code': '24310',
    Label: 'Cold drawing of bars',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dc6',
    },
    id: '203',
    'SIC Code': '24420',
    Label: 'Aluminium production',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dd6',
    },
    id: '219',
    'SIC Code': '25610',
    Label: 'Treatment and coating of metals',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986de7',
    },
    id: '236',
    'SIC Code': '26512',
    Label: 'Manufacture of electronic industrial process control equipment',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dee',
    },
    id: '243',
    'SIC Code': '26800',
    Label: 'Manufacture of magnetic and optical media',
    Categories: 'Film, Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986df1',
    },
    id: '246',
    'SIC Code': '27200',
    Label: 'Manufacture of batteries and accumulators',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986df4',
    },
    id: '249',
    'SIC Code': '27330',
    Label: 'Manufacture of wiring devices',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e00',
    },
    id: '261',
    'SIC Code': '28220',
    Label: 'Manufacture of lifting and handling equipment',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e09',
    },
    id: '270',
    'SIC Code': '28910',
    Label: 'Manufacture of machinery for metallurgy',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e2d',
    },
    id: '306',
    'SIC Code': '32990',
    Label: 'Other manufacturing n.e.c.',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e30',
    },
    id: '309',
    'SIC Code': '33130',
    Label: 'Repair of electronic and optical equipment',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e40',
    },
    id: '325',
    'SIC Code': '37000',
    Label: 'Sewerage',
    Categories: 'Utitlies',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e41',
    },
    id: '326',
    'SIC Code': '38110',
    Label: 'Collection of non-hazardous waste',
    Categories: 'Environment',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e4f',
    },
    id: '340',
    'SIC Code': '42220',
    Label: 'Construction of utility projects for electricity and telecommunications',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e63',
    },
    id: '360',
    'SIC Code': '45190',
    Label: 'Sale of other motor vehicles',
    Categories: 'Transport, Cars',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e69',
    },
    id: '366',
    'SIC Code': '46120',
    Label: 'Agents involved in the sale of fuels, ores, metals and industrial chemicals',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e6d',
    },
    id: '370',
    'SIC Code': '46160',
    Label: 'Agents involved in the sale of textiles, clothing, fur, footwear and leather goods',
    Categories: 'Fashion, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e78',
    },
    id: '381',
    'SIC Code': '46341',
    Label: 'Wholesale of fruit and vegetable juices, mineral water and soft drinks',
    Categories: 'Food, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e86',
    },
    id: '395',
    'SIC Code': '46470',
    Label: 'Wholesale of furniture, carpets and lighting equipment',
    Categories: 'Household, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e8c',
    },
    id: '401',
    'SIC Code': '46610',
    Label: 'Wholesale of agricultural machinery, equipment and supplies',
    Categories: 'Farming, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e98',
    },
    id: '413',
    'SIC Code': '46750',
    Label: 'Wholesale of chemical products',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e9b',
    },
    id: '416',
    'SIC Code': '46900',
    Label: 'Non-specialised wholesale trade',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ea8',
    },
    id: '429',
    'SIC Code': '47429',
    Label: 'Retail sale of telecommunications equipment other than mobile telephones',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eeb',
    },
    id: '496',
    'SIC Code': '55202',
    Label: 'Youth hostels',
    Categories: 'Accomodation',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f00',
    },
    id: '517',
    'SIC Code': '59113',
    Label: 'Television programme production activities',
    Categories: 'Film, Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f19',
    },
    id: '542',
    'SIC Code': '64201',
    Label: 'Activities of agricultural holding companies',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f2c',
    },
    id: '561',
    'SIC Code': '65110',
    Label: 'Life insurance',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f3d',
    },
    id: '578',
    'SIC Code': '68320',
    Label: 'Management of real estate on a fee or contract basis',
    Categories: 'Property',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f41',
    },
    id: '582',
    'SIC Code': '69201',
    Label: 'Accounting and auditing activities',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f5f',
    },
    id: '612',
    'SIC Code': '77110',
    Label: 'Renting and leasing of cars and light motor vehicles',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f61',
    },
    id: '614',
    'SIC Code': '77210',
    Label: 'Renting and leasing of recreational and sports goods',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f73',
    },
    id: '632',
    'SIC Code': '79120',
    Label: 'Tour operator activities',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f7e',
    },
    id: '643',
    'SIC Code': '81229',
    Label: 'Other building and industrial cleaning activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fa2',
    },
    id: '679',
    'SIC Code': '86210',
    Label: 'General medical practice activities',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fcb',
    },
    id: '720',
    'SIC Code': '95290',
    Label: 'Repair of personal and household goods n.e.c.',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fcd',
    },
    id: '722',
    'SIC Code': '96020',
    Label: 'Hairdressing and other beauty treatment',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fd2',
    },
    id: '727',
    'SIC Code': '98000',
    Label: 'Residents property management',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fd3',
    },
    id: '728',
    'SIC Code': '98100',
    Label: 'Undifferentiated goods-producing activities of private households for own use',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d1c',
    },
    id: '33',
    'SIC Code': '2100',
    Label: 'Silviculture and other forestry activities',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d35',
    },
    id: '58',
    'SIC Code': '10120',
    Label: 'Processing and preserving of poultry meat',
    Categories: 'Food, Butcher',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d38',
    },
    id: '61',
    'SIC Code': '10310',
    Label: 'Processing and preserving of potatoes',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d3f',
    },
    id: '68',
    'SIC Code': '10519',
    Label: 'Manufacture of other milk products',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d52',
    },
    id: '87',
    'SIC Code': '11010',
    Label: 'Distilling, rectifying and blending of spirits',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986da8',
    },
    id: '173',
    'SIC Code': '23120',
    Label: 'Shaping and processing of flat glass',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986da9',
    },
    id: '174',
    'SIC Code': '23130',
    Label: 'Manufacture of hollow glass',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dab',
    },
    id: '176',
    'SIC Code': '23190',
    Label: 'Manufacture and processing of other glass, including technical glassware',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986db6',
    },
    id: '187',
    'SIC Code': '23610',
    Label: 'Manufacture of concrete products for construction purposes',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dd1',
    },
    id: '214',
    'SIC Code': '25210',
    Label: 'Manufacture of central heating radiators and boilers',
    Categories: 'Building',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dde',
    },
    id: '227',
    'SIC Code': '25940',
    Label: 'Manufacture of fasteners and screw machine products',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ddf',
    },
    id: '228',
    'SIC Code': '25990',
    Label: 'Manufacture of other fabricated metal products n.e.c.',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986df5',
    },
    id: '250',
    'SIC Code': '27400',
    Label: 'Manufacture of electric lighting equipment',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e17',
    },
    id: '284',
    'SIC Code': '29320',
    Label: 'Manufacture of other parts and accessories for motor vehicles',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e39',
    },
    id: '318',
    'SIC Code': '35130',
    Label: 'Distribution of electricity',
    Categories: 'Energy',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e3a',
    },
    id: '319',
    'SIC Code': '35140',
    Label: 'Trade of electricity',
    Categories: 'Energy',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e47',
    },
    id: '332',
    'SIC Code': '39000',
    Label: 'Remediation activities and other waste management services',
    Categories: 'Environment',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e6a',
    },
    id: '367',
    'SIC Code': '46130',
    Label: 'Agents involved in the sale of timber and building materials',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e70',
    },
    id: '373',
    'SIC Code': '46190',
    Label: 'Agents involved in the sale of a variety of goods',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e99',
    },
    id: '414',
    'SIC Code': '46760',
    Label: 'Wholesale of other intermediate products',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eb8',
    },
    id: '445',
    'SIC Code': '47730',
    Label: 'Dispensing chemist in specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ed3',
    },
    id: '472',
    'SIC Code': '50300',
    Label: 'Inland passenger water transport',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ee3',
    },
    id: '488',
    'SIC Code': '52242',
    Label: 'Cargo handling for air transport activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ee9',
    },
    id: '494',
    'SIC Code': '55100',
    Label: 'Hotels and similar accommodation',
    Categories: 'Accomodation',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986efa',
    },
    id: '511',
    'SIC Code': '58142',
    Label: 'Publishing of consumer and business journals and periodicals',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f11',
    },
    id: '534',
    'SIC Code': '62090',
    Label: 'Other information technology service activities',
    Categories: 'IT',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f43',
    },
    id: '584',
    'SIC Code': '69203',
    Label: 'Tax consultancy',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f49',
    },
    id: '590',
    'SIC Code': '71112',
    Label: 'Urban planning and landscape architectural activities',
    Categories: 'Construction, Building',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f7c',
    },
    id: '641',
    'SIC Code': '81222',
    Label: 'Specialised cleaning services',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f97',
    },
    id: '668',
    'SIC Code': '85320',
    Label: 'Technical and vocational secondary education',
    Categories: 'Training',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fac',
    },
    id: '689',
    'SIC Code': '88990',
    Label: 'Other social work activities without accommodation n.e.c.',
    Categories: 'Care, Charities',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fb4',
    },
    id: '697',
    'SIC Code': '91030',
    Label: 'Operation of historical sites and buildings and similar visitor attractions',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fb7',
    },
    id: '700',
    'SIC Code': '93110',
    Label: 'Operation of sports facilities',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fd4',
    },
    id: '729',
    'SIC Code': '98200',
    Label: 'Undifferentiated service-producing activities of private households for own use',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d0d',
    },
    id: '18',
    'SIC Code': '1410',
    Label: 'Raising of dairy cattle',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986cfc',
    },
    id: '1',
    'SIC Code': '1110',
    Label: 'Growing of cereals (except rice), leguminous crops and oil seeds',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d08',
    },
    id: '13',
    'SIC Code': '1260',
    Label: 'Growing of oleaginous fruits',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d10',
    },
    id: '21',
    'SIC Code': '1440',
    Label: 'Raising of camels and camelids',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d12',
    },
    id: '23',
    'SIC Code': '1460',
    Label: 'Raising of swine/pigs',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d23',
    },
    id: '40',
    'SIC Code': '3220',
    Label: 'Freshwater aquaculture',
    Categories: 'Fishing',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d4a',
    },
    id: '79',
    'SIC Code': '10831',
    Label: 'Tea processing',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d53',
    },
    id: '88',
    'SIC Code': '11020',
    Label: 'Manufacture of wine from grape',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d5e',
    },
    id: '99',
    'SIC Code': '13921',
    Label: 'Manufacture of soft furnishings',
    Categories: 'Interior Design, Household',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d75',
    },
    id: '122',
    'SIC Code': '16210',
    Label: 'Manufacture of veneer sheets and wood-based panels',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d7a',
    },
    id: '127',
    'SIC Code': '17110',
    Label: 'Manufacture of pulp',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d7d',
    },
    id: '130',
    'SIC Code': '17219',
    Label: 'Manufacture of other paper and paperboard containers',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986da2',
    },
    id: '167',
    'SIC Code': '22190',
    Label: 'Manufacture of other rubber products',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dba',
    },
    id: '191',
    'SIC Code': '23650',
    Label: 'Manufacture of fibre cement',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dcf',
    },
    id: '212',
    'SIC Code': '25110',
    Label: 'Manufacture of metal structures and parts of structures',
    Categories: 'Building',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986de8',
    },
    id: '237',
    'SIC Code': '26513',
    Label:
      'Manufacture of non-electronic measuring, testing etc. equipment, not for industrial process control',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e1f',
    },
    id: '292',
    'SIC Code': '30990',
    Label: 'Manufacture of other transport equipment n.e.c.',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e27',
    },
    id: '300',
    'SIC Code': '32200',
    Label: 'Manufacture of musical instruments',
    Categories: 'Music',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e31',
    },
    id: '310',
    'SIC Code': '33140',
    Label: 'Repair of electrical equipment',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e5e',
    },
    id: '355',
    'SIC Code': '43910',
    Label: 'Roofing activities',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e6b',
    },
    id: '368',
    'SIC Code': '46140',
    Label: 'Agents involved in the sale of machinery, industrial equipment, ships and aircraft',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e90',
    },
    id: '405',
    'SIC Code': '46650',
    Label: 'Wholesale of office furniture',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e9c',
    },
    id: '417',
    'SIC Code': '47110',
    Label: 'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ea9',
    },
    id: '430',
    'SIC Code': '47430',
    Label: 'Retail sale of audio and video equipment in specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eca',
    },
    id: '463',
    'SIC Code': '49311',
    Label:
      'Urban and suburban passenger railway transportation by underground, metro and similar systems',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ecb',
    },
    id: '464',
    'SIC Code': '49319',
    Label:
      'Other urban, suburban or metropolitan passenger land transport (not underground, metro or similar)',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ed8',
    },
    id: '477',
    'SIC Code': '51220',
    Label: 'Space transport',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ef7',
    },
    id: '508',
    'SIC Code': '58120',
    Label: 'Publishing of directories and mailing lists',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ef8',
    },
    id: '509',
    'SIC Code': '58130',
    Label: 'Publishing of newspapers',
    Categories: 'Journalism',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f16',
    },
    id: '539',
    'SIC Code': '64110',
    Label: 'Central banking',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f21',
    },
    id: '550',
    'SIC Code': '64303',
    Label: 'Activities of venture and development capital companies',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f26',
    },
    id: '555',
    'SIC Code': '64921',
    Label:
      'Credit granting by non-deposit taking finance houses and other specialist consumer credit grantors',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f44',
    },
    id: '585',
    'SIC Code': '70100',
    Label: 'Activities of head offices',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f63',
    },
    id: '616',
    'SIC Code': '77291',
    Label: 'Renting and leasing of media entertainment equipment',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f64',
    },
    id: '617',
    'SIC Code': '77299',
    Label: 'Renting and leasing of other personal and household goods',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fa1',
    },
    id: '678',
    'SIC Code': '86102',
    Label: 'Medical nursing home activities',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fb0',
    },
    id: '693',
    'SIC Code': '90040',
    Label: 'Operation of arts facilities',
    Categories: 'Film, Media, Music',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fbd',
    },
    id: '706',
    'SIC Code': '93290',
    Label: 'Other amusement and recreation activities n.e.c.',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fc6',
    },
    id: '715',
    'SIC Code': '95210',
    Label: 'Repair of consumer electronics',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d4e',
    },
    id: '83',
    'SIC Code': '10860',
    Label: 'Manufacture of homogenized food preparations and dietetic food',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d51',
    },
    id: '86',
    'SIC Code': '10920',
    Label: 'Manufacture of prepared pet foods',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d63',
    },
    id: '104',
    'SIC Code': '13940',
    Label: 'Manufacture of cordage, rope, twine and netting',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d6d',
    },
    id: '114',
    'SIC Code': '14190',
    Label: 'Manufacture of other wearing apparel and accessories n.e.c.',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d77',
    },
    id: '124',
    'SIC Code': '16230',
    Label: "Manufacture of other builders' carpentry and joinery",
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d7e',
    },
    id: '131',
    'SIC Code': '17220',
    Label: 'Manufacture of household and sanitary goods and of toilet requisites',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d83',
    },
    id: '136',
    'SIC Code': '18121',
    Label: 'Manufacture of printed labels',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d92',
    },
    id: '151',
    'SIC Code': '20160',
    Label: 'Manufacture of plastics in primary forms',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986da4',
    },
    id: '169',
    'SIC Code': '22220',
    Label: 'Manufacture of plastic packing goods',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986db4',
    },
    id: '185',
    'SIC Code': '23510',
    Label: 'Manufacture of cement',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986db8',
    },
    id: '189',
    'SIC Code': '23630',
    Label: 'Manufacture of ready-mixed concrete',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dbb',
    },
    id: '192',
    'SIC Code': '23690',
    Label: 'Manufacture of other articles of concrete, plaster and cement',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dce',
    },
    id: '211',
    'SIC Code': '24540',
    Label: 'Casting of other non-ferrous metals',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dd0',
    },
    id: '213',
    'SIC Code': '25120',
    Label: 'Manufacture of doors and windows of metal',
    Categories: 'Building',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ddd',
    },
    id: '226',
    'SIC Code': '25930',
    Label: 'Manufacture of wire products, chain and springs',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986de6',
    },
    id: '235',
    'SIC Code': '26511',
    Label:
      'Manufacture of electronic measuring, testing etc. equipment, not for industrial process control',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e06',
    },
    id: '267',
    'SIC Code': '28302',
    Label: 'Manufacture of agricultural and forestry machinery other than tractors',
    Categories: 'Farming, Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e2f',
    },
    id: '308',
    'SIC Code': '33120',
    Label: 'Repair of machinery',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e38',
    },
    id: '317',
    'SIC Code': '35120',
    Label: 'Transmission of electricity',
    Categories: 'Energy',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e5c',
    },
    id: '353',
    'SIC Code': '43342',
    Label: 'Glazing',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e9f',
    },
    id: '420',
    'SIC Code': '47220',
    Label: 'Retail sale of meat and meat products in specialised stores',
    Categories: 'Food, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ea4',
    },
    id: '425',
    'SIC Code': '47290',
    Label: 'Other retail sale of food in specialised stores',
    Categories: 'Food, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ead',
    },
    id: '434',
    'SIC Code': '47540',
    Label: 'Retail sale of electrical household appliances in specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eaf',
    },
    id: '436',
    'SIC Code': '47599',
    Label:
      'Retail of furniture, lighting, and similar (not musical instruments or scores) in specialised store',
    Categories: 'Household, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eb3',
    },
    id: '440',
    'SIC Code': '47640',
    Label: 'Retail sale of sports goods, fishing gear, camping goods, boats and bicycles',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eb4',
    },
    id: '441',
    'SIC Code': '47650',
    Label: 'Retail sale of games and toys in specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ec8',
    },
    id: '461',
    'SIC Code': '49100',
    Label: 'Passenger rail transport, interurban',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ed1',
    },
    id: '470',
    'SIC Code': '50100',
    Label: 'Sea and coastal passenger water transport',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ee1',
    },
    id: '486',
    'SIC Code': '52230',
    Label: 'Service activities incidental to air transportation',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ee6',
    },
    id: '491',
    'SIC Code': '53100',
    Label: 'Postal activities under universal service obligation',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ef1',
    },
    id: '502',
    'SIC Code': '56103',
    Label: 'Take-away food shops and mobile food stands',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ef5',
    },
    id: '506',
    'SIC Code': '56302',
    Label: 'Public houses and bars',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986efc',
    },
    id: '513',
    'SIC Code': '58210',
    Label: 'Publishing of computer games',
    Categories: 'Software',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f01',
    },
    id: '518',
    'SIC Code': '59120',
    Label: 'Motion picture, video and television programme post-production activities',
    Categories: 'Film, Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f03',
    },
    id: '520',
    'SIC Code': '59132',
    Label: 'Video distribution activities',
    Categories: 'Film, Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f1b',
    },
    id: '544',
    'SIC Code': '64203',
    Label: 'Activities of construction holding companies',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f20',
    },
    id: '549',
    'SIC Code': '64302',
    Label: 'Activities of unit trusts',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f67',
    },
    id: '620',
    'SIC Code': '77330',
    Label: 'Renting and leasing of office machinery and equipment (including computers)',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f7a',
    },
    id: '639',
    'SIC Code': '81210',
    Label: 'General cleaning of buildings',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f81',
    },
    id: '646',
    'SIC Code': '81300',
    Label: 'Landscape service activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f84',
    },
    id: '649',
    'SIC Code': '82200',
    Label: 'Activities of call centres',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f87',
    },
    id: '652',
    'SIC Code': '82911',
    Label: 'Activities of collection agencies',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f88',
    },
    id: '653',
    'SIC Code': '82912',
    Label: 'Activities of credit bureaus',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f8e',
    },
    id: '659',
    'SIC Code': '84210',
    Label: 'Foreign affairs',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986faf',
    },
    id: '692',
    'SIC Code': '90030',
    Label: 'Artistic creation',
    Categories: 'Film, Media, Music',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fbf',
    },
    id: '708',
    'SIC Code': '94120',
    Label: 'Activities of professional membership organizations',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fcf',
    },
    id: '724',
    'SIC Code': '96040',
    Label: 'Physical well-being activities',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fd6',
    },
    id: '731',
    'SIC Code': '99999',
    Label: 'Dormant Company',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d01',
    },
    id: '6',
    'SIC Code': '1160',
    Label: 'Growing of fibre crops',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d15',
    },
    id: '26',
    'SIC Code': '1500',
    Label: 'Mixed farming',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d18',
    },
    id: '29',
    'SIC Code': '1629',
    Label:
      'Support activities for animal production (other than farm animal boarding and care) n.e.c.',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d1b',
    },
    id: '32',
    'SIC Code': '1700',
    Label: 'Hunting, trapping and related service activities',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d41',
    },
    id: '70',
    'SIC Code': '10611',
    Label: 'Grain milling',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d43',
    },
    id: '72',
    'SIC Code': '10620',
    Label: 'Manufacture of starches and starch products',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d47',
    },
    id: '76',
    'SIC Code': '10810',
    Label: 'Manufacture of sugar',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d4d',
    },
    id: '82',
    'SIC Code': '10850',
    Label: 'Manufacture of prepared meals and dishes',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d57',
    },
    id: '92',
    'SIC Code': '11060',
    Label: 'Manufacture of malt',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d87',
    },
    id: '140',
    'SIC Code': '18201',
    Label: 'Reproduction of sound recording',
    Categories: 'Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986daa',
    },
    id: '175',
    'SIC Code': '23140',
    Label: 'Manufacture of glass fibres',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dad',
    },
    id: '178',
    'SIC Code': '23310',
    Label: 'Manufacture of ceramic tiles and flags',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986db9',
    },
    id: '190',
    'SIC Code': '23640',
    Label: 'Manufacture of mortars',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986df7',
    },
    id: '252',
    'SIC Code': '27520',
    Label: 'Manufacture of non-electric domestic appliances',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dff',
    },
    id: '260',
    'SIC Code': '28210',
    Label: 'Manufacture of ovens, furnaces and furnace burners',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e0f',
    },
    id: '276',
    'SIC Code': '28950',
    Label: 'Manufacture of machinery for paper and paperboard production',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e22',
    },
    id: '295',
    'SIC Code': '31030',
    Label: 'Manufacture of mattresses',
    Categories: 'Interior Design, Furniture',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e37',
    },
    id: '316',
    'SIC Code': '35110',
    Label: 'Production of electricity',
    Categories: 'Energy',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e3c',
    },
    id: '321',
    'SIC Code': '35220',
    Label: 'Distribution of gaseous fuels through mains',
    Categories: 'Utitlies, Energy',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e4d',
    },
    id: '338',
    'SIC Code': '42130',
    Label: 'Construction of bridges and tunnels',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e50',
    },
    id: '341',
    'SIC Code': '42910',
    Label: 'Construction of water projects',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e51',
    },
    id: '342',
    'SIC Code': '42990',
    Label: 'Construction of other civil engineering projects n.e.c.',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e52',
    },
    id: '343',
    'SIC Code': '43110',
    Label: 'Demolition',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e62',
    },
    id: '359',
    'SIC Code': '45112',
    Label: 'Sale of used cars and light motor vehicles',
    Categories: 'Transport, Cars',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e7d',
    },
    id: '386',
    'SIC Code': '46380',
    Label: 'Wholesale of other food, including fish, crustaceans and molluscs',
    Categories: 'Food, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e83',
    },
    id: '392',
    'SIC Code': '46440',
    Label: 'Wholesale of china and glassware and cleaning materials',
    Categories: 'Household, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e85',
    },
    id: '394',
    'SIC Code': '46460',
    Label: 'Wholesale of pharmaceutical goods',
    Categories: 'Medical, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e94',
    },
    id: '409',
    'SIC Code': '46719',
    Label: 'Wholesale of other fuels and related products',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e9d',
    },
    id: '418',
    'SIC Code': '47190',
    Label: 'Other retail sale in non-specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ec0',
    },
    id: '453',
    'SIC Code': '47789',
    Label:
      'Other retail sale of new goods in specialised stores (not commercial art galleries and opticians)',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ed5',
    },
    id: '474',
    'SIC Code': '51101',
    Label: 'Scheduled passenger air transport',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986edc',
    },
    id: '481',
    'SIC Code': '52211',
    Label: 'Operation of rail freight terminals',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ede',
    },
    id: '483',
    'SIC Code': '52213',
    Label: 'Operation of bus and coach passenger facilities at bus and coach stations',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ee8',
    },
    id: '493',
    'SIC Code': '53202',
    Label: 'Unlicensed carrier',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f29',
    },
    id: '558',
    'SIC Code': '64991',
    Label: 'Security dealing on own account',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f31',
    },
    id: '566',
    'SIC Code': '66110',
    Label: 'Administration of financial markets',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f37',
    },
    id: '572',
    'SIC Code': '66300',
    Label: 'Fund management activities',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f39',
    },
    id: '574',
    'SIC Code': '68201',
    Label: 'Renting and operating of Housing Association real estate',
    Categories: 'Property',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f3a',
    },
    id: '575',
    'SIC Code': '68202',
    Label: 'Letting and operating of conference and exhibition centres',
    Categories: 'Property',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f5c',
    },
    id: '609',
    'SIC Code': '74909',
    Label: 'Other professional, scientific and technical activities n.e.c.',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f5e',
    },
    id: '611',
    'SIC Code': '75000',
    Label: 'Veterinary activities',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f62',
    },
    id: '615',
    'SIC Code': '77220',
    Label: 'Renting of video tapes and disks',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f6e',
    },
    id: '627',
    'SIC Code': '78101',
    Label: 'Motion picture, television and other theatrical casting activities',
    Categories: 'Film, Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f71',
    },
    id: '630',
    'SIC Code': '78300',
    Label: 'Human resources provision and management of human resources functions',
    Categories: 'Recruitment',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fa0',
    },
    id: '677',
    'SIC Code': '86101',
    Label: 'Hospital activities',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fb2',
    },
    id: '695',
    'SIC Code': '91012',
    Label: 'Archives activities',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fb3',
    },
    id: '696',
    'SIC Code': '91020',
    Label: 'Museums activities',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fb5',
    },
    id: '698',
    'SIC Code': '91040',
    Label: 'Botanical and zoological gardens and nature reserves activities',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fbe',
    },
    id: '707',
    'SIC Code': '94110',
    Label: 'Activities of business and employers membership organizations',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fc4',
    },
    id: '713',
    'SIC Code': '95110',
    Label: 'Repair of computers and peripheral equipment',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fca',
    },
    id: '719',
    'SIC Code': '95250',
    Label: 'Repair of watches, clocks and jewellery',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d02',
    },
    id: '7',
    'SIC Code': '1190',
    Label: 'Growing of other non-perennial crops',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d0f',
    },
    id: '20',
    'SIC Code': '1430',
    Label: 'Raising of horses and other equines',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d34',
    },
    id: '57',
    'SIC Code': '10110',
    Label: 'Processing and preserving of meat',
    Categories: 'Food, Butcher',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d39',
    },
    id: '62',
    'SIC Code': '10320',
    Label: 'Manufacture of fruit and vegetable juice',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d54',
    },
    id: '89',
    'SIC Code': '11030',
    Label: 'Manufacture of cider and other fruit wines',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d7f',
    },
    id: '132',
    'SIC Code': '17230',
    Label: 'Manufacture of paper stationery',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d96',
    },
    id: '155',
    'SIC Code': '20302',
    Label: 'Manufacture of printing ink',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d99',
    },
    id: '158',
    'SIC Code': '20420',
    Label: 'Manufacture of perfumes and toilet preparations',
    Categories: 'Beauty',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986da7',
    },
    id: '172',
    'SIC Code': '23110',
    Label: 'Manufacture of flat glass',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dcc',
    },
    id: '209',
    'SIC Code': '24520',
    Label: 'Casting of steel',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dcd',
    },
    id: '210',
    'SIC Code': '24530',
    Label: 'Casting of light metals',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e1a',
    },
    id: '287',
    'SIC Code': '30200',
    Label: 'Manufacture of railway locomotives and rolling stock',
    Categories: 'Rail, Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e21',
    },
    id: '294',
    'SIC Code': '31020',
    Label: 'Manufacture of kitchen furniture',
    Categories: 'Interior Design, Furniture',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e28',
    },
    id: '301',
    'SIC Code': '32300',
    Label: 'Manufacture of sports goods',
    Categories: 'Sports',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e29',
    },
    id: '302',
    'SIC Code': '32401',
    Label: 'Manufacture of professional and arcade games and toys',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e3e',
    },
    id: '323',
    'SIC Code': '35300',
    Label: 'Steam and air conditioning supply',
    Categories: 'Utitlies, Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e48',
    },
    id: '333',
    'SIC Code': '41100',
    Label: 'Development of building projects',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e4c',
    },
    id: '337',
    'SIC Code': '42120',
    Label: 'Construction of railways and underground railways',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e4e',
    },
    id: '339',
    'SIC Code': '42210',
    Label: 'Construction of utility projects for fluids',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e67',
    },
    id: '364',
    'SIC Code': '45400',
    Label: 'Sale, maintenance and repair of motorcycles and related parts and accessories',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e75',
    },
    id: '378',
    'SIC Code': '46310',
    Label: 'Wholesale of fruit and vegetables',
    Categories: 'Food, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e7c',
    },
    id: '385',
    'SIC Code': '46370',
    Label: 'Wholesale of coffee, tea, cocoa and spices',
    Categories: 'Food, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eaa',
    },
    id: '431',
    'SIC Code': '47510',
    Label: 'Retail sale of textiles in specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ec4',
    },
    id: '457',
    'SIC Code': '47820',
    Label: 'Retail sale via stalls and markets of textiles, clothing and footwear',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ec6',
    },
    id: '459',
    'SIC Code': '47910',
    Label: 'Retail sale via mail order houses or via Internet',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ed0',
    },
    id: '469',
    'SIC Code': '49500',
    Label: 'Transport via pipeline',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eec',
    },
    id: '497',
    'SIC Code': '55209',
    Label: 'Other holiday and other collective accommodation',
    Categories: 'Accomodation',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ef6',
    },
    id: '507',
    'SIC Code': '58110',
    Label: 'Book publishing',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f02',
    },
    id: '519',
    'SIC Code': '59131',
    Label: 'Motion picture distribution activities',
    Categories: 'Film, Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f0d',
    },
    id: '530',
    'SIC Code': '62011',
    Label: 'Ready-made interactive leisure and entertainment software development',
    Categories: 'Software, Computer Games',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f10',
    },
    id: '533',
    'SIC Code': '62030',
    Label: 'Computer facilities management activities',
    Categories: 'IT',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f14',
    },
    id: '537',
    'SIC Code': '63910',
    Label: 'News agency activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f1e',
    },
    id: '547',
    'SIC Code': '64209',
    Label: 'Activities of other holding companies n.e.c.',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f23',
    },
    id: '552',
    'SIC Code': '64305',
    Label: 'Activities of property unit trusts',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f3b',
    },
    id: '576',
    'SIC Code': '68209',
    Label: 'Other letting and operating of own or leased real estate',
    Categories: 'Property',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f45',
    },
    id: '586',
    'SIC Code': '70210',
    Label: 'Public relations and communications activities',
    Categories: 'Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f5a',
    },
    id: '607',
    'SIC Code': '74901',
    Label: 'Environmental consulting activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f6d',
    },
    id: '626',
    'SIC Code': '77400',
    Label: 'Leasing of intellectual property and similar products, except copyright works',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f86',
    },
    id: '651',
    'SIC Code': '82302',
    Label: 'Activities of conference organisers',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f8f',
    },
    id: '660',
    'SIC Code': '84220',
    Label: 'Defence activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f9b',
    },
    id: '672',
    'SIC Code': '85510',
    Label: 'Sports and recreation education',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f9c',
    },
    id: '673',
    'SIC Code': '85520',
    Label: 'Cultural education',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fa9',
    },
    id: '686',
    'SIC Code': '87900',
    Label: 'Other residential care activities n.e.c.',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986faa',
    },
    id: '687',
    'SIC Code': '88100',
    Label: 'Social work activities without accommodation for the elderly and disabled',
    Categories: 'Care, Charities',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fab',
    },
    id: '688',
    'SIC Code': '88910',
    Label: 'Child day-care activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fbc',
    },
    id: '705',
    'SIC Code': '93210',
    Label: 'Activities of amusement parks and theme parks',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fc5',
    },
    id: '714',
    'SIC Code': '95120',
    Label: 'Repair of communication equipment',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fd5',
    },
    id: '730',
    'SIC Code': '99000',
    Label: 'Activities of extraterritorial organizations and bodies',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d0c',
    },
    id: '17',
    'SIC Code': '1300',
    Label: 'Plant propagation',
    Categories: 'Farming, Crops, Flowers',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d00',
    },
    id: '5',
    'SIC Code': '1150',
    Label: 'Growing of tobacco',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d14',
    },
    id: '25',
    'SIC Code': '1490',
    Label: 'Raising of other animals',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d28',
    },
    id: '45',
    'SIC Code': '6200',
    Label: 'Extraction of natural gas',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d44',
    },
    id: '73',
    'SIC Code': '10710',
    Label: 'Manufacture of bread; manufacture of fresh pastry goods and cakes',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d4b',
    },
    id: '80',
    'SIC Code': '10832',
    Label: 'Production of coffee and coffee substitutes',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d62',
    },
    id: '103',
    'SIC Code': '13939',
    Label: 'Manufacture of other carpets and rugs',
    Categories: 'Interior Design, Household',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d68',
    },
    id: '109',
    'SIC Code': '14120',
    Label: 'Manufacture of workwear',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d8f',
    },
    id: '148',
    'SIC Code': '20130',
    Label: 'Manufacture of other inorganic basic chemicals',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d95',
    },
    id: '154',
    'SIC Code': '20301',
    Label: 'Manufacture of paints, varnishes and similar coatings, mastics and sealants',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d9e',
    },
    id: '163',
    'SIC Code': '20600',
    Label: 'Manufacture of man-made fibres',
    Categories: 'Textiles, Fashion',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986daf',
    },
    id: '180',
    'SIC Code': '23410',
    Label: 'Manufacture of ceramic household and ornamental articles',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dbf',
    },
    id: '196',
    'SIC Code': '24100',
    Label: 'Manufacture of basic iron and steel and of ferro-alloys',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dc4',
    },
    id: '201',
    'SIC Code': '24340',
    Label: 'Cold drawing of wire',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986df0',
    },
    id: '245',
    'SIC Code': '27120',
    Label: 'Manufacture of electricity distribution and control apparatus',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dfd',
    },
    id: '258',
    'SIC Code': '28140',
    Label: 'Manufacture of taps and valves',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e03',
    },
    id: '264',
    'SIC Code': '28250',
    Label: 'Manufacture of non-domestic cooling and ventilation equipment',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e08',
    },
    id: '269',
    'SIC Code': '28490',
    Label: 'Manufacture of other machine tools',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e3f',
    },
    id: '324',
    'SIC Code': '36000',
    Label: 'Water collection, treatment and supply',
    Categories: 'Utitlies',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e49',
    },
    id: '334',
    'SIC Code': '41201',
    Label: 'Construction of commercial buildings',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e58',
    },
    id: '349',
    'SIC Code': '43310',
    Label: 'Plastering',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e60',
    },
    id: '357',
    'SIC Code': '43999',
    Label: 'Other specialised construction activities n.e.c.',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e6c',
    },
    id: '369',
    'SIC Code': '46150',
    Label: 'Agents involved in the sale of furniture, household goods, hardware and ironmongery',
    Categories: 'Retail',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e6f',
    },
    id: '372',
    'SIC Code': '46180',
    Label: 'Agents specialized in the sale of other particular products',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e72',
    },
    id: '375',
    'SIC Code': '46220',
    Label: 'Wholesale of flowers and plants',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e79',
    },
    id: '382',
    'SIC Code': '46342',
    Label: 'Wholesale of wine, beer, spirits and other alcoholic beverages',
    Categories: 'Food, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e7f',
    },
    id: '388',
    'SIC Code': '46410',
    Label: 'Wholesale of textiles',
    Categories: 'Fashion, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e87',
    },
    id: '396',
    'SIC Code': '46480',
    Label: 'Wholesale of watches and jewellery',
    Categories: 'Fashion, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e89',
    },
    id: '398',
    'SIC Code': '46499',
    Label: 'Wholesale of household goods (other than musical instruments) n.e.c.',
    Categories: 'Household, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e9a',
    },
    id: '415',
    'SIC Code': '46770',
    Label: 'Wholesale of waste and scrap',
    Categories: 'Construction, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eb0',
    },
    id: '437',
    'SIC Code': '47610',
    Label: 'Retail sale of books in specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eb6',
    },
    id: '443',
    'SIC Code': '47721',
    Label: 'Retail sale of footwear in specialised stores',
    Categories: 'Fashion, Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ee0',
    },
    id: '485',
    'SIC Code': '52220',
    Label: 'Service activities incidental to water transportation',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ee7',
    },
    id: '492',
    'SIC Code': '53201',
    Label: 'Licensed carriers',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eea',
    },
    id: '495',
    'SIC Code': '55201',
    Label: 'Holiday centres and villages',
    Categories: 'Accomodation',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f1c',
    },
    id: '545',
    'SIC Code': '64204',
    Label: 'Activities of distribution holding companies',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f2d',
    },
    id: '562',
    'SIC Code': '65120',
    Label: 'Non-life insurance',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f34',
    },
    id: '569',
    'SIC Code': '66210',
    Label: 'Risk and damage evaluation',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f50',
    },
    id: '597',
    'SIC Code': '72200',
    Label: 'Research and experimental development on social sciences and humanities',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f51',
    },
    id: '598',
    'SIC Code': '73110',
    Label: 'Advertising agencies',
    Categories: 'Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f5d',
    },
    id: '610',
    'SIC Code': '74990',
    Label: 'Non-trading company',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f6b',
    },
    id: '624',
    'SIC Code': '77352',
    Label: 'Renting and leasing of freight air transport equipment',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d03',
    },
    id: '8',
    'SIC Code': '1210',
    Label: 'Growing of grapes',
    Categories: 'Farming, Crops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d19',
    },
    id: '30',
    'SIC Code': '1630',
    Label: 'Post-harvest crop activities',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d20',
    },
    id: '37',
    'SIC Code': '3110',
    Label: 'Marine fishing',
    Categories: 'Fishing',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d29',
    },
    id: '46',
    'SIC Code': '7100',
    Label: 'Mining of iron ores',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d3d',
    },
    id: '66',
    'SIC Code': '10511',
    Label: 'Liquid milk and cream production',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d6c',
    },
    id: '113',
    'SIC Code': '14142',
    Label: "Manufacture of women's underwear",
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d84',
    },
    id: '137',
    'SIC Code': '18129',
    Label: 'Printing n.e.c.',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d86',
    },
    id: '139',
    'SIC Code': '18140',
    Label: 'Binding and related services',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d8a',
    },
    id: '143',
    'SIC Code': '19100',
    Label: 'Manufacture of coke oven products',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986db1',
    },
    id: '182',
    'SIC Code': '23430',
    Label: 'Manufacture of ceramic insulators and insulating fittings',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dc0',
    },
    id: '197',
    'SIC Code': '24200',
    Label: 'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ddb',
    },
    id: '224',
    'SIC Code': '25910',
    Label: 'Manufacture of steel drums and similar containers',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986de4',
    },
    id: '233',
    'SIC Code': '26309',
    Label:
      'Manufacture of communication equipment other than telegraph, and telephone apparatus and equipment',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e0b',
    },
    id: '272',
    'SIC Code': '28922',
    Label: 'Manufacture of earthmoving equipment',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e1b',
    },
    id: '288',
    'SIC Code': '30300',
    Label: 'Manufacture of air and spacecraft and related machinery',
    Categories: 'Aerospace, Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e1e',
    },
    id: '291',
    'SIC Code': '30920',
    Label: 'Manufacture of bicycles and invalid carriages',
    Categories: 'Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e24',
    },
    id: '297',
    'SIC Code': '32110',
    Label: 'Striking of coins',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e2b',
    },
    id: '304',
    'SIC Code': '32500',
    Label: 'Manufacture of medical and dental instruments and supplies',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e2e',
    },
    id: '307',
    'SIC Code': '33110',
    Label: 'Repair of fabricated metal products',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e43',
    },
    id: '328',
    'SIC Code': '38210',
    Label: 'Treatment and disposal of non-hazardous waste',
    Categories: 'Environment',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e4b',
    },
    id: '336',
    'SIC Code': '42110',
    Label: 'Construction of roads and motorways',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e55',
    },
    id: '346',
    'SIC Code': '43210',
    Label: 'Electrical installation',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e59',
    },
    id: '350',
    'SIC Code': '43320',
    Label: 'Joinery installation',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e5b',
    },
    id: '352',
    'SIC Code': '43341',
    Label: 'Painting',
    Categories: 'Construction',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e6e',
    },
    id: '371',
    'SIC Code': '46170',
    Label: 'Agents involved in the sale of food, beverages and tobacco',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e7a',
    },
    id: '383',
    'SIC Code': '46350',
    Label: 'Wholesale of tobacco products',
    Categories: 'Food, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e7b',
    },
    id: '384',
    'SIC Code': '46360',
    Label: 'Wholesale of sugar and chocolate and sugar confectionery',
    Categories: 'Food, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e80',
    },
    id: '389',
    'SIC Code': '46420',
    Label: 'Wholesale of clothing and footwear',
    Categories: 'Fashion, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e84',
    },
    id: '393',
    'SIC Code': '46450',
    Label: 'Wholesale of perfume and cosmetics',
    Categories: 'Beauty, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e8a',
    },
    id: '399',
    'SIC Code': '46510',
    Label: 'Wholesale of computers, computer peripheral equipment and software',
    Categories: 'Electronics, Software',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e8e',
    },
    id: '403',
    'SIC Code': '46630',
    Label: 'Wholesale of mining, construction and civil engineering machinery',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ea7',
    },
    id: '428',
    'SIC Code': '47421',
    Label: 'Retail sale of mobile telephones',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ebe',
    },
    id: '451',
    'SIC Code': '47781',
    Label: 'Retail sale in commercial art galleries',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ebf',
    },
    id: '452',
    'SIC Code': '47782',
    Label: 'Retail sale by opticians',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ec2',
    },
    id: '455',
    'SIC Code': '47799',
    Label: 'Retail sale of other second-hand goods in stores (not incl. antiques)',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ecd',
    },
    id: '466',
    'SIC Code': '49390',
    Label: 'Other passenger land transport',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ed9',
    },
    id: '478',
    'SIC Code': '52101',
    Label: 'Operation of warehousing and storage facilities for water transport activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eda',
    },
    id: '479',
    'SIC Code': '52102',
    Label: 'Operation of warehousing and storage facilities for air transport activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f12',
    },
    id: '535',
    'SIC Code': '63110',
    Label: 'Data processing, hosting and related activities',
    Categories: 'IT',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f2f',
    },
    id: '564',
    'SIC Code': '65202',
    Label: 'Non-life reinsurance',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f48',
    },
    id: '589',
    'SIC Code': '71111',
    Label: 'Architectural activities',
    Categories: 'Construction, Building',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f55',
    },
    id: '602',
    'SIC Code': '74201',
    Label: 'Portrait photographic activities',
    Categories: 'Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f56',
    },
    id: '603',
    'SIC Code': '74202',
    Label: 'Other specialist photography',
    Categories: 'Media',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f83',
    },
    id: '648',
    'SIC Code': '82190',
    Label: 'Photocopying, document preparation and other specialised office support activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f93',
    },
    id: '664',
    'SIC Code': '84300',
    Label: 'Compulsory social security activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fa4',
    },
    id: '681',
    'SIC Code': '86230',
    Label: 'Dental practice activities',
    Categories: 'Medical',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fad',
    },
    id: '690',
    'SIC Code': '90010',
    Label: 'Performing arts',
    Categories: 'Film, Media, Music',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fae',
    },
    id: '691',
    'SIC Code': '90020',
    Label: 'Support activities to performing arts',
    Categories: 'Film, Media, Music',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fc9',
    },
    id: '718',
    'SIC Code': '95240',
    Label: 'Repair of furniture and home furnishings',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fce',
    },
    id: '723',
    'SIC Code': '96030',
    Label: 'Funeral and related activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d11',
    },
    id: '22',
    'SIC Code': '1450',
    Label: 'Raising of sheep and  goats',
    Categories: 'Farming',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d1e',
    },
    id: '35',
    'SIC Code': '2300',
    Label: 'Gathering of wild growing non-wood products',
    Categories: 'Farming, Foraging',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d21',
    },
    id: '38',
    'SIC Code': '3120',
    Label: 'Freshwater fishing',
    Categories: 'Fishing',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d2c',
    },
    id: '49',
    'SIC Code': '8110',
    Label: 'Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate',
    Categories: 'Mining',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d4c',
    },
    id: '81',
    'SIC Code': '10840',
    Label: 'Manufacture of condiments and seasonings',
    Categories: 'Food, Sauces',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d55',
    },
    id: '90',
    'SIC Code': '11040',
    Label: 'Manufacture of other non-distilled fermented beverages',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d66',
    },
    id: '107',
    'SIC Code': '13990',
    Label: 'Manufacture of other textiles n.e.c.',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d67',
    },
    id: '108',
    'SIC Code': '14110',
    Label: 'Manufacture of leather clothes',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d69',
    },
    id: '110',
    'SIC Code': '14131',
    Label: "Manufacture of other men's outerwear",
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d6f',
    },
    id: '116',
    'SIC Code': '14310',
    Label: 'Manufacture of knitted and crocheted hosiery',
    Categories: 'Fashion, Clothes',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d88',
    },
    id: '141',
    'SIC Code': '18202',
    Label: 'Reproduction of video recording',
    Categories: 'Media, Film',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d93',
    },
    id: '152',
    'SIC Code': '20170',
    Label: 'Manufacture of synthetic rubber in primary forms',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d94',
    },
    id: '153',
    'SIC Code': '20200',
    Label: 'Manufacture of pesticides and other agrochemical products',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986d9b',
    },
    id: '160',
    'SIC Code': '20520',
    Label: 'Manufacture of glues',
    Categories: 'Materials',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dc3',
    },
    id: '200',
    'SIC Code': '24330',
    Label: 'Cold forming or folding',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986dca',
    },
    id: '207',
    'SIC Code': '24460',
    Label: 'Processing of nuclear fuel',
    Categories: 'Metal',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ddc',
    },
    id: '225',
    'SIC Code': '25920',
    Label: 'Manufacture of light metal packaging',
    Categories: 'Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986def',
    },
    id: '244',
    'SIC Code': '27110',
    Label: 'Manufacture of electric motors, generators and transformers',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986df8',
    },
    id: '253',
    'SIC Code': '27900',
    Label: 'Manufacture of other electrical equipment',
    Categories: 'Electronics',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986df9',
    },
    id: '254',
    'SIC Code': '28110',
    Label: 'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e14',
    },
    id: '281',
    'SIC Code': '29202',
    Label: 'Manufacture of trailers and semi-trailers',
    Categories: 'Mechanics, Engineering',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e19',
    },
    id: '286',
    'SIC Code': '30120',
    Label: 'Building of pleasure and sporting boats',
    Categories: 'Maritime, Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e32',
    },
    id: '311',
    'SIC Code': '33150',
    Label: 'Repair and maintenance of ships and boats',
    Categories: 'Maritime, Transport',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e35',
    },
    id: '314',
    'SIC Code': '33190',
    Label: 'Repair of other equipment',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e68',
    },
    id: '365',
    'SIC Code': '46110',
    Label:
      'Agents selling agricultural raw materials, livestock, textile raw materials and semi-finished goods',
    Categories: 'Fashion',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986e7e',
    },
    id: '387',
    'SIC Code': '46390',
    Label: 'Non-specialised wholesale of food, beverages and tobacco',
    Categories: 'Food, Products',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ea6',
    },
    id: '427',
    'SIC Code': '47410',
    Label: 'Retail sale of computers, peripheral units and software in specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eab',
    },
    id: '432',
    'SIC Code': '47520',
    Label: 'Retail sale of hardware, paints and glass in specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ebc',
    },
    id: '449',
    'SIC Code': '47760',
    Label:
      'Retail sale of flowers, plants, seeds, fertilizers, pet animals and pet food in specialised stores',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ec3',
    },
    id: '456',
    'SIC Code': '47810',
    Label: 'Retail sale via stalls and markets of food, beverages and tobacco products',
    Categories: 'Shops',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ece',
    },
    id: '467',
    'SIC Code': '49410',
    Label: 'Freight transport by road',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ed6',
    },
    id: '475',
    'SIC Code': '51102',
    Label: 'Non-scheduled passenger air transport',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ed7',
    },
    id: '476',
    'SIC Code': '51210',
    Label: 'Freight air transport',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ee4',
    },
    id: '489',
    'SIC Code': '52243',
    Label: 'Cargo handling for land transport activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eed',
    },
    id: '498',
    'SIC Code': '55300',
    Label: 'Recreational vehicle parks, trailer parks and camping grounds',
    Categories: 'Accomodation',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eee',
    },
    id: '499',
    'SIC Code': '55900',
    Label: 'Other accommodation',
    Categories: 'Accomodation',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986eef',
    },
    id: '500',
    'SIC Code': '56101',
    Label: 'Licensed restaurants',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986ef3',
    },
    id: '504',
    'SIC Code': '56290',
    Label: 'Other food services',
    Categories: 'Food',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986efb',
    },
    id: '512',
    'SIC Code': '58190',
    Label: 'Other publishing activities',
    Categories: 'Books, Music',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f30',
    },
    id: '565',
    'SIC Code': '65300',
    Label: 'Pension funding',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f35',
    },
    id: '570',
    'SIC Code': '66220',
    Label: 'Activities of insurance agents and brokers',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f38',
    },
    id: '573',
    'SIC Code': '68100',
    Label: 'Buying and selling of own real estate',
    Categories: 'Property',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f3c',
    },
    id: '577',
    'SIC Code': '68310',
    Label: 'Real estate agencies',
    Categories: 'Property',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f40',
    },
    id: '581',
    'SIC Code': '69109',
    Label: 'Activities of patent and copyright agents; other legal activities n.e.c.',
    Categories: 'Legal, IP',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f47',
    },
    id: '588',
    'SIC Code': '70229',
    Label: 'Management consultancy activities other than financial management',
    Categories: 'Financial',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f4a',
    },
    id: '591',
    'SIC Code': '71121',
    Label: 'Engineering design activities for industrial process and production',
    Categories: 'Construction, Building',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f4d',
    },
    id: '594',
    'SIC Code': '71200',
    Label: 'Technical testing and analysis',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f59',
    },
    id: '606',
    'SIC Code': '74300',
    Label: 'Translation and interpretation activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f66',
    },
    id: '619',
    'SIC Code': '77320',
    Label: 'Renting and leasing of construction and civil engineering machinery and equipment',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f69',
    },
    id: '622',
    'SIC Code': '77342',
    Label: 'Renting and leasing of freight water transport equipment',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f6f',
    },
    id: '628',
    'SIC Code': '78109',
    Label: 'Other activities of employment placement agencies',
    Categories: 'Recruitment',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f76',
    },
    id: '635',
    'SIC Code': '80100',
    Label: 'Private security activities',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f7d',
    },
    id: '642',
    'SIC Code': '81223',
    Label: 'Furnace and chimney cleaning services',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986f98',
    },
    id: '669',
    'SIC Code': '85410',
    Label: 'Post-secondary non-tertiary education',
    Categories: 'Training',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fbb',
    },
    id: '704',
    'SIC Code': '93199',
    Label: 'Other sports activities',
    Categories: 'Leisure',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fc0',
    },
    id: '709',
    'SIC Code': '94200',
    Label: 'Activities of trade unions',
    Categories: '',
  },
  {
    _id: {
      $oid: '61ae4794d896ee16a1986fd0',
    },
    id: '725',
    'SIC Code': '96090',
    Label: 'Other personal service activities n.e.c.',
    Categories: '',
  },
];
